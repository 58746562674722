import React from "react";
// import global from "../config";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import ClearIcon from "@material-ui/icons/Clear";
import "./CustomSidebar.css";
import { Trans } from "react-i18next";

import { Link } from "react-router-dom";
const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

export default class CustomSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { left: false, actual: location.pathname };
  }

  toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.props.handleOpen();
    this.setState({ ...this.state, [anchor]: open });
  };
  followLink(target) {
    this.setState({ actual: target });
    this.toggleDrawer("left", false);
    this.props.handleOpen();
  }
  render() {
    return (
      <React.Fragment>
        <SwipeableDrawer
          anchor={"left"}
          open={this.state["left"] || this.props.shouldOpen}
          onClose={this.toggleDrawer("left", false)}
          onOpen={this.toggleDrawer("left", true)}
        >
          <div style={{ width: "250px" }} className="sidebar">
            <img
              alt="logo"
              className="header-brand-img"
              src="/static/images/brand/logo.png"
            />
            <div className="close_icon">
              <ClearIcon onClick={() => this.followLink()} />
            </div>
            <div className="sidebar_ul_container">
              <ul>
                <li
                  className={this.state.actual === "/" ? "active" : null}
                  onClick={() => this.followLink("/")}
                >
                  <Link to="/">
                    <i className="fe fe-grid" /> Dashboard{" "}
                  </Link>
                </li>
                <li
                  className={
                    this.state.actual === "/project/list" ? "active" : null
                  }
                  onClick={() => this.followLink("/project/list")}
                >
                  <Link to="/project/list">
                    {" "}
                    <i className="fe fe-box"></i> Project{" "}
                  </Link>
                </li>
                <hr />
                {[
                  "project",
                  "project_builds",
                  "project_settings",
                  "branch",
                  "branch_settings",
                ].includes(this.props.cS.active_menu) && (
                  <li
                    className={
                      this.state.actual ===
                      (this.props.cS.active_project
                        ? "/project/" +
                          String(this.props.cS.active_project.project_id[0].id)
                        : "null")
                        ? "active"
                        : null
                    }
                  >
                    <Link
                      onClick={() =>
                        this.followLink(
                          this.props.cS.active_project
                            ? "/project/" +
                                String(
                                  this.props.cS.active_project.project_id[0].id
                                )
                            : "null"
                        )
                      }
                      to={
                        this.props.cS.active_project
                          ? "/project/" +
                            String(
                              this.props.cS.active_project.project_id[0].id
                            )
                          : ""
                      }
                    >
                      <i className="fe fe-box" />{" "}
                      {this.props.cS.active_project
                        ? this.props.cS.active_project.project_id[0].name
                        : "Loading..."}
                    </Link>
                  </li>
                )}
                {["project", "project_builds", "project_settings"].includes(
                  this.props.cS.active_menu
                ) && (
                  <li
                    className={
                      this.state.actual ===
                      (this.props.cS.active_project
                        ? "/project/" +
                          String(
                            this.props.cS.active_project.project_id[0].id
                          ) +
                          "/settings"
                        : "null")
                        ? "active"
                        : null
                    }
                  >
                    <Link
                      onClick={() =>
                        this.followLink(
                          this.props.cS.active_project
                            ? "/project/" +
                                String(
                                  this.props.cS.active_project.project_id[0].id
                                ) +
                                "/settings"
                            : ""
                        )
                      }
                      to={
                        this.props.cS.active_project
                          ? "/project/" +
                            String(
                              this.props.cS.active_project.project_id[0].id
                            ) +
                            "/settings"
                          : ""
                      }
                    >
                      <i className="fe fe-settings" />{" "}
                      <Trans i18nKey="settings">Settings</Trans>
                    </Link>
                  </li>
                )}
                {["project", "project_builds", "project_settings"].includes(
                  this.props.cS.active_menu
                ) && (
                  <li
                    className={
                      this.state.actual ===
                      (this.props.cS.active_project
                        ? "/project/" +
                          String(
                            this.props.cS.active_project.project_id[0].id
                          ) +
                          "/builds"
                        : "null")
                        ? "active"
                        : null
                    }
                  >
                    <Link
                      onClick={() =>
                        this.followLink(
                          this.props.cS.active_project
                            ? "/project/" +
                                String(
                                  this.props.cS.active_project.project_id[0].id
                                ) +
                                "/builds"
                            : ""
                        )
                      }
                      to={
                        this.props.cS.active_project
                          ? "/project/" +
                            String(
                              this.props.cS.active_project.project_id[0].id
                            ) +
                            "/builds"
                          : ""
                      }
                    >
                      <i className="fe fe-server" /> Builds
                    </Link>
                  </li>
                )}
                {["branch", "branch_settings"].includes(
                  this.props.cS.active_menu
                ) && (
                  <li
                    className={
                      (
                        this.state.actual === this.props.cS.active_branch
                          ? "/branch/" +
                            String(this.props.cS.active_branch.branch_id[0].id)
                          : "null"
                      )
                        ? "active"
                        : null
                    }
                  >
                    <Link
                      onClick={() => this.followLink()}
                      to={
                        this.props.cS.active_branch
                          ? "/branch/" +
                            String(this.props.cS.active_branch.branch_id[0].id)
                          : ""
                      }
                    >
                      <i className="fe fe-git-branch" />{" "}
                      {this.props.cS.active_branch
                        ? this.props.cS.active_branch.branch_id[0].name
                        : "Loading..."}
                    </Link>
                  </li>
                )}
                {["branch", "branch_settings"].includes(
                  this.props.cS.active_menu
                ) && (
                  <li
                    className={
                      this.state.actual ===
                      (this.props.cS.active_branch
                        ? "/branch/" +
                          String(this.props.cS.active_branch.branch_id[0].id) +
                          "/settings"
                        : "nulla")
                        ? "active"
                        : null
                    }
                  >
                    <Link
                      onClick={() =>
                        this.followLink(
                          this.props.cS.active_branch
                            ? "/branch/" +
                                String(
                                  this.props.cS.active_branch.branch_id[0].id
                                ) +
                                "/settings"
                            : ""
                        )
                      }
                      to={
                        this.props.cS.active_branch
                          ? "/branch/" +
                            String(
                              this.props.cS.active_branch.branch_id[0].id
                            ) +
                            "/settings"
                          : ""
                      }
                    >
                      <i className="fe fe-settings" />{" "}
                      <Trans i18nKey="settings">Settings</Trans>
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </SwipeableDrawer>
      </React.Fragment>
    );
  }
}
