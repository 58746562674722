import React from "react";
import global from "../config";
import "../config";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

import { Redirect } from "react-router-dom";
import SnapshotActionsModal from "./SnapshotActionsModal";
const axios = require("axios").default;
axios.defaults.withCredentials = true;
const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip placement="top" arrow classes={classes} {...props} />;
}
class SnapshotActions extends React.Component {
  constructor(props) {
    super(props);
    this.restoreToStage = this.restoreToStage.bind(this);
    this.restoreToProduction = this.restoreToProduction.bind(this);
    this.headers = global.header_api;
    this.state = {
      loading: false,
      redirectCreate: false,
    };
  }

  rSSnapshot = () => {
    this.props.handleCS({modal: {
        branchId: this.props.branchId,
        onClick: this.restoreToStage
      }});
  }

  rPSnapshot = () => {
    this.props.handleCS({modal: {
        branchId: this.props.branchId,
        onClick: this.restoreToProduction
      }});
  }

  restoreToStage(name) {
    this.setState({
      loading: true,
    });
    const body = {
      branch_id: this.props.branchId.id,
      environment: "staging",
      restore_from: this.props.snapshotId.name,
      new_branch: name
    };
    axios
      .post(global.url_api + "/rest/data/build/create", body, {
        headers: this.headers,
      })
      .then((res) => {
        this.setState({
          redirectCreate: true,
        });
        const data = res.data;
        this.setState({
          loading: false,
        });
        if (this.props.cS.active_branch) {
          this.props.handleCS(data);
        } else {
          this.props.handleCS({ active_project: data.active_project });
        }
        window.swal(
          "Congratulations!",
          "Your stack has been successfully deployed",
          "success"
        );
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        window.swal({
          title: "Alert",
          text:
            "Ops! Something went wrong:\n\n" + error.response.data.description,
          type: "error",
        });
      });
  }

  restoreToProduction(name) {
    this.setState({
      loading: true,
    });
    const body = {
      branch_id: this.props.branchId.id,
      environment: "production",
      restore_from: this.props.snapshotId.name,
      new_branch: name
    };
    axios
      .post(global.url_api + "/rest/data/build/create", body, {
        headers: this.headers,
      })
      .then((res) => {
        this.setState({
          redirectCreate: true,
        });
        const data = res.data;
        this.setState({
          loading: false,
        });
        if (this.props.cS.active_branch) {
          this.props.handleCS(data);
        } else {
          this.props.handleCS({ active_project: data.active_project });
        }
        window.swal(
          "Congratulations!",
          "Your stack has been successfully deployed",
          "success"
        );
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        window.swal({
          title: "Alert",
          text:
            "Ops! Something went wrong:\n\n" + error.response.data.description,
          type: "error",
        });
      });
  }

  deleteSnapshot = () => {
    var that = this;

    this.setState({
      loading: true,
    });
    window.swal(
      {
        title: "WARNING!",
        text:
          "Please be careful, deleting will erase the snapshot definitely! Are you sure you wish to proceed?",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn-danger",
        confirmButtonText: "Delete it!",
        cancelButtonText: "Close",
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
      },
      (inputValue) => {
        this.setState({
          loading: false,
        });
        if (inputValue) {
          axios
            .delete(
              global.url_api +
                "/rest/data/snapshot/" +
                String(this.props.snapshotId.id),
              {
                headers: this.headers,
              }
            )
            .then((res) => {
              const data = res.data;
              this.props.handleCS(data);
              window.swal(
                "Congratulations!",
                "Your snapshot has been successfully deleted",
                "success"
              );
            })
            .catch((error) => {
              console.log(error)
              // window.swal({
              //   title: "Alert",
              //   text:
              //     "Ops! Something went wrong:\n\n" +
              //     error.response.data.description,
              //   type: "error",
              // });
            });
        }
      }
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.state.redirectCreate && (
          <Redirect to={"/branch/" + String(this.props.branchId.id)} />
        )}

        <div className="btn-list">
            <React.Fragment>
              <BootstrapTooltip title="Deploy to Stage">
                <button
                  type="button"
                  className={
                    this.state.loading
                      ? "btn btn-info btn-icon btn-sm btn-loading"
                      : "btn btn-info btn-icon btn-sm"
                  }
                  data-toggle="tooltip"
                  data-placement="top"
                  data-toggle="modal"
                  data-target="#branchActionsRestoreSnapshotModal"
                  onClick={this.rSSnapshot}
                >
                  <i className="fas fa-file-code"></i>
                </button>
              </BootstrapTooltip>
              <BootstrapTooltip title="Deploy to Production">
                <button
                  type="button"
                  className={
                    this.state.loading
                      ? "btn btn-icon btn-primary btn-sm btn-loading"
                      : "btn btn-icon btn-primary btn-sm"
                  }
                  data-toggle="tooltip"
                  data-placement="top"
                  data-toggle="modal"
                  data-target="#branchActionsRestoreSnapshotModal"
                  onClick={this.rPSnapshot}
                >
                  <i className="fas fa-rocket"></i>
                </button>
              </BootstrapTooltip>
              <BootstrapTooltip title="Delete">
                <button
                  type="button"
                  className={
                    this.state.loading
                      ? "btn btn-icon btn-pinterest btn-sm btn-loading"
                      : "btn btn-icon btn-pinterest btn-sm"
                  }
                  data-toggle="modal"
                  data-target="#delModal"
                  data-placement="top"
                  onClick={this.deleteSnapshot}
                >
                  <i className="fa fa-trash"></i>
                </button>
              </BootstrapTooltip>
            </React.Fragment>
          {/*{this.props.branchId.environment !== 'not_assigned' && (*/}
          {/*<button type="button"*/}
          {/*className="btn btn-icon btn-pinterest btn-sm"*/}
          {/*data-toggle="modal" data-target="#delModal"*/}
          {/*data-placement="top" title="Delete">*/}
          {/*<i className="fa fa-wrench"></i></button>*/}
          {/*)}*/}
        </div>
      </React.Fragment>
    );
  }
}

export default SnapshotActions;
