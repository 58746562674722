import React, { useState, useEffect } from "react";
import { Trans } from "react-i18next";
import global from "../config";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import Swal from "sweetalert2/dist/sweetalert2.all.min.js";
const axios = require("axios").default;
axios.defaults.withCredentials = true;
const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});
const BranchDeleteModal = ({ cS, handleCS }) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  useEffect(() => {
    if (cS.modal) {
      setName("");
    }
  }, [cS]);
  function deleteBranch() {
    setLoading(true);
    axios
      .delete(global.url_api + "/rest/data/branch/" + cS.selectedBranch.id)
      .then((res) => {
        setLoading(false);
        $("#branchActionsDeleteModal").modal("toggle");
        const data = res.data;
        if (cS.active_branch) {
          handleCS(data);
        } else {
          handleCS({ active_project: data.active_project });
        }
        window.swal(
          "Congratulations!",
          "Your branch has been successfully deleted",
          "success"
        );
      })
      .catch((error) => {
        setLoading(false);
        window.swal({
          title: "Alert",
          text:
            "Ops! Something went wrong:\n\n" + error.response.data.description,
          type: "error",
        });
      });
  }
  const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();

    return <Tooltip placement="top" arrow classes={classes} {...props} />;
  }
  return (
    <div
      className="modal fade"
      id="branchActionsDeleteModal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="create-snapshot-modal">
              <Trans i18nKey="delete_branch">Delete Branch</Trans>
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form>
              <div className="form-group">
                <p>
                  Inserisci il nome del branch riportato per confermare
                  l'operazione, l'azione è irreversibile
                </p>
                <BootstrapTooltip title="Clicca per copiare il nome">
                  <input
                    onClick={() => {
                      var elem = document.createElement("textarea");
                      document.body.appendChild(elem);
                      elem.value = cS.selectedBranch.name;
                      elem.select();
                      document.execCommand("copy");
                      Toast.fire({
                        icon: "info",
                        title: "Copiato negli appunti",
                        text: cS.selectedBranch.name,
                      });
                      document.body.removeChild(elem);
                    }}
                    type="text"
                    className={"form-control disabled"}
                    id="old-branch-name"
                    value={cS.selectedBranch.name}
                  />
                </BootstrapTooltip>
                <label htmlFor="recipient-name" className="form-control-label">
                  <Trans i18nKey="branch_name">Nome del branch</Trans>
                </label>
                <input
                  type="text"
                  placeholder="Inserisci il nome del branch da cancellare... "
                  className="form-control"
                  id="delete-branch-name"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              disabled={name !== cS.selectedBranch.name}
              type="button"
              className={"btn btn-primary" + (loading ? " btn-loading" : "")}
              onClick={() => {
                deleteBranch();
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BranchDeleteModal;
