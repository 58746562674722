import React from "react";
// import global from "../config";
import "./Footer.css";
class Footer extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/*footer*/}
        <footer className="footer">
            <div className="d-flex flex-column align-items-center h-100">
              <div className="d-flex h-100 copyright">
                Copyright © 2024 &nbsp;
                <a href="https://www.rwsdigital.com/">RWSdigital</a>. All rights
                reserved.
              </div>
            </div>
        </footer>
        {/* End Footer*/}
      </React.Fragment>
    );
  }
}

export default Footer;
