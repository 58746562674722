import React from "react";
import global from "../config";
import LoadingSpinner from "./LoadingSpinner";
import { Formik, Field } from "formik";
import "../config";
/* eslint-disable */
const axios = require("axios").default;
axios.defaults.withCredentials = true;

import { Link, Redirect } from "react-router-dom";
import { Trans } from "react-i18next";

class ProjectInit extends React.Component {
  constructor(props) {
    super(props);
    this.props.handleCS({
      active_menu: "project_init",
      active_project: null,
      active_branch: null,
    });
    this.state = {
      data: null,
    };
  }

  componentDidMount() {
    const qsValues = new URLSearchParams(this.props.location.search);
    this.setState({ git_type: qsValues.get("type"), username: qsValues.get("username") });

    const headers = global.header_api;
    axios
      .get(
        global.url_api +
          "/rest/data/project/" +
          String(this.props.match.params.pid) +
          "?type=" +
          qsValues.get("type") + "&username=" + qsValues.get("username"),
        {
          headers: headers,
        }
      )
      .then((res) => {
        const data = res.data;
        this.setState({ data: data });
      })
      .catch(function (error) {
        console.log(error.toJSON());
        this.setState({ err: true });
      });
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-header">
          <h3 className="page-title">
            <i className="fe fe-box" />{" "}
            {this.state.data ? "Initialize Your Project" : "Loading..."}
          </h3>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/project/list/">
                <Trans i18nKey="projects">Projects</Trans>
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <Trans i18nKey="initialize">Initialize</Trans>
            </li>
          </ol>
        </div>

        {this.state.data ? (
          <div className="row">
            <div className="col-lg-6">
              <div className="card">
                <div className="card-body">
                  <Formik
                    initialValues={{
                      projectInit: true,
                      projectName: this.state.data.project_id[0].name,
                      project: this.props.match.params.pid,
                      gitType: this.state.git_type,
                      repoType: "new",
                      repositoryName: "",
                      repositoryNameList: this.state.data.repo_list[0],
                      odooVersion: "17.0",
                      username: this.state.username
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      const headers = global.header_api;
                      const body = values;
                      // console.log(body);

                      axios
                        .post(
                          global.url_api +
                            "/rest/data/project/" +
                            String(this.state.data.project_id[0].id),
                          body,
                          {
                            headers: headers,
                          }
                        )
                        .then((res) => {
                          window.swal(
                            "Congratulations!",
                            "Project successfully initialized!",
                            "success"
                          );
                          return window.location = "/project/list";
                        })
                        .catch((error) => {
                          window.swal({
                            title: "Alert",
                            text:
                              "Ops! Something went wrong:\n\n" +
                              error.toJSON().message,
                            type: "error",
                          });
                          // console.log(error.toJSON());
                          this.setState({ err: true });
                        });

                      setSubmitting(false);

                      // debug purpose
                      // setTimeout(() => {
                      //     alert(JSON.stringify(values, null, 2));
                      //     setSubmitting(false);
                      // }, 400);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                    }) => (
                      <div>
                        <form onSubmit={handleSubmit}>
                          <div className="row">
                            <div className="col-md-12 col-lg-12">
                              <div
                                className="form-group m-0"
                                name="project-name-group"
                              >
                                <label className="form-label">
                                  Project Name
                                </label>
                                <input
                                  type="text"
                                  name="projectName"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.projectName}
                                  className={
                                    errors.projectName && touched.projectName
                                      ? "form-control is-invalid state-invalid"
                                      : "form-control"
                                  }
                                />
                                {errors.projectName && touched.projectName && (
                                  <div className="invalid-feedback">
                                    {errors.projectName}
                                  </div>
                                )}
                              </div>
                              <div className="form-group">
                                <input
                                  type="hidden"
                                  name="project"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.project}
                                />
                                <input
                                  type="hidden"
                                  name="gitType"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.gitType}
                                />
                                <div className="form-label">Repository</div>
                                <div className="custom-controls-stacked">
                                  <label className="custom-control custom-radio">
                                    <Field
                                      type="radio"
                                      className="custom-control-input"
                                      name="repoType"
                                      value="new"
                                    />
                                    <span className="custom-control-label">
                                      New repository
                                    </span>
                                  </label>
                                  <label className="custom-control custom-radio">
                                    <Field
                                      type="radio"
                                      className="custom-control-input"
                                      name="repoType"
                                      value="existing"
                                    />
                                    <span className="custom-control-label">
                                      Existing repository with Odoo modules
                                    </span>
                                  </label>
                                </div>
                              </div>
                              {values.repoType == "new" && (
                                <div
                                  className="form-group"
                                  name="create-repository-group"
                                >
                                  <label className="form-label">
                                    Repository Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="repositoryName"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.repositoryName}
                                    className={
                                      errors.repositoryName &&
                                      touched.repositoryName
                                        ? "form-control is-invalid state-invalid"
                                        : "form-control"
                                    }
                                  />
                                  {errors.repositoryName &&
                                    touched.repositoryName && (
                                      <div className="invalid-feedback">
                                        {errors.repositoryName}
                                      </div>
                                    )}
                                </div>
                              )}
                              {values.repoType == "existing" && (
                                <div
                                  className="form-group"
                                  name="choose-repository-group"
                                >
                                  <label className="form-label">
                                    Choose Repository
                                  </label>
                                  <Field
                                    as="select"
                                    name="repositoryNameList"
                                    className="form-control select2 custom-select select2-hidden-accessible"
                                    aria-hidden="true"
                                  >
                                    {this.state.data.repo_list.map(
                                      (repo, index) => {
                                        return (
                                          <option key={index} value={repo}>
                                            {repo}
                                          </option>
                                        );
                                      }
                                    )}
                                  </Field>
                                </div>
                              )}
                              <div className="form-group">
                                <label className="form-label">
                                  Odoo Version
                                </label>
                                <Field
                                    as="select"
                                    name="odooVersion"
                                    className="form-control select2 custom-select select2-hidden-accessible"
                                    aria-hidden="true"
                                    defaultValue="14.0"
                                >
                                  <option value="17.0">17.0</option>
                                  <option value="16.0">16.0</option>
                                  <option value="14.0">14.0</option>
                                  <option value="13.0">13.0</option>
                                  <option value="12.0">12.0</option>
                                  <option value="11.0">11.0</option>
                                  <option value="10.0">10.0</option>
                                  {/*</select>*/}
                                </Field>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 col-lg-12">
                              <div className=" mt-2 mb-0">
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                  disabled={isSubmitting}
                                >
                                  Setup
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="hero-widget card">
                    <i className="fas fa-cog  fa-3x mb-4 text-primary" />
                    <div className="text">
                      <var>
                        {this.state.data.project_id[0].plan_id.n_users_prod}
                      </var>
                      <label className="text-muted">concurrent users</label>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="hero-widget card">
                    <i className="fas fa-hdd  fa-3x mb-4 text-primary" />
                    <div className="text">
                      <var>
                        {
                          this.state.data.project_id[0].plan_id
                            .odoo_storage_prod
                        }
                        gb
                      </var>
                      <label className="text-muted">storage</label>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="hero-widget card">
                    <i className="fas fa-file-code  fa-3x mb-4 text-primary" />
                    <div className="text">
                      <var>{this.state.data.project_id[0].max_stage_env}</var>
                      <label className="text-muted">stage env</label>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="hero-widget card">
                    <i className="fas fa-rocket  fa-3x mb-4 text-primary" />
                    <div className="text">
                      <var>{this.state.data.project_id[0].max_prod_env}</var>
                      <label className="text-muted">production env</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <LoadingSpinner err={this.state.err} />
        )}
      </React.Fragment>
    );
  }
}

export default ProjectInit;
