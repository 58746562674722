import React from "react";
// import global from "../config";
import { Trans } from "react-i18next";

export default class BranchEnvironment extends React.Component {
  render() {
    const prefix = this.props.prefix;
    const env = this.props.environment;
    switch (env) {
      case "not_assigned":
        return (
          <React.Fragment>
            {prefix} <Trans i18nKey="not_assigned">Not Assigned</Trans>
          </React.Fragment>
        );
      case "production":
        return <React.Fragment>{prefix} Production</React.Fragment>;
      case "staging":
        return <React.Fragment>{prefix} Staging</React.Fragment>;
      default:
        return null;
    }
  }
}
