/* eslint react/prop-types: 0 */
import React from "react";
import global from "../config";
import LoadingSpinner from "./LoadingSpinner";
import { Formik, Field } from "formik";
import "../config";
import "./BranchSettings.css";
const axios = require("axios").default;
axios.defaults.withCredentials = true;

import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import { Animated } from "react-animated-css";
import { Tooltip } from "@material-ui/core";
import Zoom from "@material-ui/core/Zoom";

class BranchSettings extends React.Component {
  constructor(props) {
    super(props);
    this.props.handleCS({
      active_menu: "branch_settings",
    });
    this.state = {
      updated: false,
      numberfield: 1,
      update_formik: true
    };
  }

  componentDidMount() {
    const headers = global.header_api;
    axios
      .get(
        global.url_api +
          "/rest/data/branch/" +
          String(this.props.match.params.bid),
        {
          headers: headers,
        }
      )
      .then((res) => {
        const data = res.data;
        this.props.handleCS(data);
        this.setState({ updated: true, numberfield: data.active_branch.branch_id[0].addons_count});
      })
      .catch(function (error) {
        console.log(error.toJSON());
        this.setState({ err: true });
      });
  }
  checkInput(values, k) {
    let elemento = document.getElementById(values);
    let valore = elemento.getAttribute("value");
    //Compare un nuovo field se incollo
    elemento.addEventListener("paste", () => {
      this.setState({ numberfield: k });
    });
    //Compare un nuovo field dopo il primo carattere che inserisco(ho pensato 1 perchè esistono cartelle del tipo /a/)
    if (valore.length === 1) this.setState({ numberfield: k });
  }

  renderForm(handleChange, handleBlur, values, errors, touched, k) {
    var i = 0;

    let res = [];
    for (i = 0; i < k; i++) {
      values["addons_count"] = this.state.numberfield;
      res.push(
        <Animated
          animationInDuration={800}
          animationOutDuration={800}
          animationOut="fadeOut"
          isVisible={true}
          key={i}
          animationIn="zoomInLeft"
          className="input_controlled"
        >
          <div className="form-group">
            <input
              id={"addons_" + i.toString()}
              type="text"
              name={"addons_" + i.toString()}
              onChange={handleChange}
              onBlur={handleBlur}
              onKeyDown={() =>
                this.checkInput("addons_" + (i - 1).toString(), k + 1)
              }
              value={values["addons_" + i.toString()]}
              className={
                errors["addons_" + i.toString()] &&
                touched["addons_" + i.toString()]
                  ? "form-control is-invalid state-invalid"
                  : "form-control"
              }
            />
            {errors["addons_" + i.toString()] &&
              touched["addons_" + i.toString()] && (
                <div className="invalid-feedback">
                  {errors["addons_" + i.toString()]}
                </div>
              )}
          </div>
        </Animated>
      );
    }
    return res;
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-header">
          <h3 className="page-title">
            {this.props.cS.active_project && this.state.updated ? (
              <Trans i18nKey="branch_settings">Branch Settings</Trans>
            ) : (
              "Loading..."
            )}
          </h3>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="dashboard/project/list/">
                {" "}
                <Trans i18nKey="projects">Projects</Trans>
              </Link>
            </li>
            {this.props.cS.active_project && this.state.updated && (
              <li className="breadcrumb-item">
                <Link
                  to={
                    "/project/" +
                    String(this.props.cS.active_project.project_id[0].id)
                  }
                >
                  {this.props.cS.active_project.project_id[0].name}
                </Link>
              </li>
            )}
            {this.props.cS.active_branch && this.state.updated && (
              <li className="breadcrumb-item">
                <Link
                  to={
                    "/branch/" +
                    String(this.props.cS.active_branch.branch_id[0].id)
                  }
                >
                  {this.props.cS.active_branch.branch_id[0].name}
                </Link>
              </li>
            )}
            <li className="breadcrumb-item active" aria-current="page">
              {" "}
              <Trans i18nKey="settings">Settings</Trans>
            </li>
          </ol>
        </div>

        {this.props.cS.active_branch && this.state.updated ? (
          <div className="row">
            <div className="col-lg-6">
              <Animated
                animationInDuration={850}
                animationOutDuration={850}
                animationOut="fadeOut"
                isVisible={this.props.cS.active_branch && this.state.updated}
                animationInDelay={200}
                animationIn="fadeIn"
                className="card"
              >
                <div className="card-header">
                  <div className="card-title">
                    {" "}
                    <Trans i18nKey="odoo_configuration">
                      Odoo Configuration
                    </Trans>
                  </div>
                </div>
                <div className="card-body">
                  { this.state.update_formik &&
                  <Formik
                    initialValues={Object.assign({}, {
                      odoo_conf_admin_pass: this.props.cS.active_branch
                        .branch_id[0].odoo_conf_admin_pass,
                    }, this.props.cS.active_branch
                        .branch_id[0].addons)}
                    validate={(values) => {
                      const errors = {};
                      let i = 0;
                      for (i = 0; i < this.state.numberfield; i++) {
                        if (
                          values["addons_" + i.toString()] !== "" &&
                          values["addons_" + i.toString()] &&
                          !/^([(A-z)(0-9)(_,\-)])+(\/?[(A-z)(0-9)(_,\-)]+\/?)*$/i.test(
                            values["addons_" + i.toString()]
                          )
                        ) {
                          errors["addons_" + i.toString()] = (
                            <Trans i18nKey="invalid_path">
                              Path not valid, expect something like
                              /PathtoAddons or /Path_to_addons
                            </Trans>
                          );
                        }
                      }

                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      const headers = global.header_api;
                      const body = values;
                      // console.log(body);

                      axios
                        .post(
                          global.url_api +
                            "/rest/data/branch/" +
                            String(
                              this.props.cS.active_branch.branch_id[0].id
                            ) +
                            "/update",
                          body,
                          {
                            headers: headers,
                          }
                        )
                        .then((res) => {
                          this.props.handleCS(res.data);
                          this.setState({ numberfield: res.data.active_branch.branch_id[0].addons_count, update_formik: false}, ()=>{
                            this.setState({
                              update_formik: true
                            })
                          });
                          window.swal(
                            "Congratulations!",
                            "Settings successfully updated. Remember to make a reload of your instance to apply the changes.",
                            "success"
                          );
                        })
                        .catch(function (error) {
                          window.swal({
                            title: "Alert",
                            text:
                              "Ops! Something went wrong:\n\n" +
                              error.toJSON().message,
                            type: "error",
                          });
                          // console.log(error.toJSON());
                          this.setState({ err: true });
                        });

                      setSubmitting(false);

                      // debug purpose
                      // setTimeout(() => {
                      //     alert(JSON.stringify(values, null, 2));
                      //     setSubmitting(false);
                      // }, 400);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                    }) => (
                      <div>
                        <form onSubmit={handleSubmit}>
                          <div>
                            <div className="form-group">
                              <label className="form-label">
                                <Trans i18nKey="admin_password">
                                  Admin Password
                                </Trans>
                              </label>
                              <input
                                type="text"
                                name="odoo_conf_admin_pass"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.odoo_conf_admin_pass}
                                className={
                                  errors.odoo_conf_admin_pass &&
                                  touched.odoo_conf_admin_pass
                                    ? "form-control is-invalid state-invalid"
                                    : "form-control"
                                }
                              />
                              {errors.odoo_conf_admin_pass &&
                                touched.odoo_conf_admin_pass && (
                                  <div className="invalid-feedback">
                                    {errors.odoo_conf_admin_pass}
                                  </div>
                                )}
                            </div>
                            <div className="form-group">
                              {/*<Tooltip*/}
                              {/*  title={*/}
                              {/*    <Trans i18nKey="addons_path_helper">*/}
                              {/*      Insert the addons folder's path, it cannot*/}
                              {/*      contain space or special character*/}
                              {/*      (è,ò,ì,ù,à,@....)*/}
                              {/*    </Trans>*/}
                              {/*  }*/}
                              {/*  arrow*/}
                              {/*  TransitionComponent={Zoom}*/}
                              {/*  placement="right-start"*/}
                              {/*>*/}

                              {/*</Tooltip>*/}
                              <label className="form-label">
                                Addons Folder
                              </label>
                              {errors.odoo_conf_addons_path &&
                                touched.odoo_conf_addons_path && (
                                  <div className="invalid-feedback">
                                    {errors.odoo_conf_addons_path}
                                  </div>
                                )}
                            </div>{" "}
                            {this.renderForm(
                              handleChange,
                              handleBlur,
                              values,
                              errors,
                              touched,
                              this.state.numberfield
                            )}
                          </div>
                          <div className="row">
                            <div className="col-md-12 col-lg-12">
                              <div className=" mt-2 mb-0">
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                  disabled={isSubmitting}
                                >
                                  Setup
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                  </Formik>
                  }
                </div>
              </Animated>
            </div>

            <div className="col-lg-6">
              <Animated
                animationInDuration={850}
                animationOutDuration={850}
                animationOut="fadeOut"
                isVisible={true}
                animationInDelay={400}
                animationIn="fadeInRight"
                className="card"
              >
                <div className="card-header">
                  <div className="card-title">Odoo Reload</div>
                </div>
                <div className="card-body">
                  <Formik
                    initialValues={{
                      modules_to_install: this.props.cS.active_branch
                        .branch_id[0].modules_to_install
                        ? this.props.cS.active_branch.branch_id[0]
                            .modules_to_install
                        : "",
                      modules_to_update: this.props.cS.active_branch
                        .branch_id[0].modules_to_update
                        ? this.props.cS.active_branch.branch_id[0]
                            .modules_to_update
                        : "",
                    }}
                    validate={(values) => {
                      const errors = {};
                      if (
                        !/^(\w+([,]?\w)+)*$/i.test(values.modules_to_update)
                      ) {
                        errors.modules_to_update =
                          "Invalid module format (ex. base,web,sale)";
                      }
                      if (
                        !/^(\w+([,]?\w)+)*$/i.test(values.modules_to_install)
                      ) {
                        errors.modules_to_install = (
                          <Trans i18nKey="invalid_module">
                            Invalid module format (ex. base,web,sale)
                          </Trans>
                        );
                      }
                      return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      const headers = global.header_api;
                      const body = values;
                      // console.log(body);

                      axios
                        .post(
                          global.url_api +
                            "/rest/data/branch/" +
                            String(
                              this.props.cS.active_branch.branch_id[0].id
                            ) +
                            "/update",
                          body,
                          {
                            headers: headers,
                          }
                        )
                        .then((res) => {
                          this.props.handleCS(res.data);

                          window.swal(
                            "Congratulations!",
                            "Settings successfully updated. Remember to make a reload of your instance to apply the changes.",
                            "success"
                          );
                        })
                        .catch(function (error) {
                          window.swal({
                            title: "Alert",
                            text:
                              "Ops! Something went wrong:\n\n" +
                              error.toJSON().message,
                            type: "error",
                          });
                          // console.log(error.toJSON());
                          this.setState({ err: true });
                        });

                      setSubmitting(false);

                      // debug purpose
                      // setTimeout(() => {
                      //     alert(JSON.stringify(values, null, 2));
                      //     setSubmitting(false);
                      // }, 400);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                    }) => (
                      <div>
                        <form onSubmit={handleSubmit}>
                          <div>
                            <div className="form-group">
                              <label className="form-label">
                                <Trans i18nKey="install_following">
                                  Install Following Modules at Reload
                                </Trans>
                              </label>
                              <input
                                type="text"
                                name="modules_to_install"
                                placeholder="Ex. base,web,sale,purchase"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.modules_to_install}
                                className={
                                  errors.modules_to_install &&
                                  touched.modules_to_install
                                    ? "form-control is-invalid state-invalid"
                                    : "form-control"
                                }
                              />
                              {errors.modules_to_install &&
                                touched.modules_to_install && (
                                  <div className="invalid-feedback">
                                    {errors.modules_to_install}
                                  </div>
                                )}
                            </div>
                            <div className="form-group">
                              <label className="form-label">
                                <Trans i18nKey="update_following">
                                  Update Following Modules at Reload
                                </Trans>
                              </label>
                              <input
                                type="text"
                                name="modules_to_update"
                                placeholder="Ex. base,web,sale,purchase"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.modules_to_update}
                                className={
                                  errors.modules_to_update &&
                                  touched.modules_to_update
                                    ? "form-control is-invalid state-invalid"
                                    : "form-control"
                                }
                              />
                              {errors.modules_to_update &&
                                touched.modules_to_update && (
                                  <div className="invalid-feedback">
                                    {errors.modules_to_update}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 col-lg-12">
                              <div className=" mt-2 mb-0">
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                  disabled={isSubmitting}
                                >
                                  Setup
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                  </Formik>
                </div>
              </Animated>
            </div>

            {this.props.cS.active_branch.branch_id[0].environment ===
              "staging" && (
              <div className="col-lg-6">
                <Animated
                  animationInDuration={850}
                  animationOutDuration={850}
                  animationOut="fadeOut"
                  isVisible={
                    this.props.cS.active_branch.branch_id[0].environment ===
                    "staging"
                  }
                  animationInDelay={600}
                  animationIn="fadeIn"
                  className="card"
                >
                  <div className="card-header">
                    <div className="card-title">
                      <Trans i18nKey="staging_settings">Staging Settings</Trans>
                    </div>
                  </div>
                  <div className="card-body">
                    <Formik
                      initialValues={{
                        is_test_mode_active: this.props.cS.active_branch
                          .branch_id[0].is_test_mode_active,
                      }}
                      onSubmit={(values, { setSubmitting }) => {
                        const headers = global.header_api;
                        const body = values;
                        // console.log(body);

                        axios
                          .post(
                            global.url_api +
                              "/rest/data/branch/" +
                              String(
                                this.props.cS.active_branch.branch_id[0].id
                              ) +
                              "/update",
                            body,
                            {
                              headers: headers,
                            }
                          )
                          .then((res) => {
                            this.props.handleCS(res.data);
                            window.swal(
                              "Congratulations!",
                              "Settings successfully updated. Remember to make a reload of your instance to apply the changes.",
                              "success"
                            );
                          })
                          .catch(function (error) {
                            window.swal({
                              title: "Alert",
                              text:
                                "Ops! Something went wrong:\n\n" +
                                error.toJSON().message,
                              type: "error",
                            });
                            // console.log(error.toJSON());
                            this.setState({ err: true });
                          });

                        setSubmitting(false);

                        // debug purpose
                        // setTimeout(() => {
                        //     alert(JSON.stringify(values, null, 2));
                        //     setSubmitting(false);
                        // }, 400);
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        /* and other goodies */
                      }) => (
                        <div>
                          <form onSubmit={handleSubmit}>
                            <div>
                              <div className="checkbox">
                                <div className="custom-checkbox custom-control">
                                  <Field
                                    className="custom-control-input"
                                    name="is_test_mode_active"
                                    component="input"
                                    type="checkbox"
                                    id="checkbox-1"
                                    checked={values.is_test_mode_active}
                                  />
                                  <label
                                    htmlFor="checkbox-1"
                                    className="custom-control-label"
                                  >
                                    Validate the test suite on new commits?
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12 col-lg-12">
                                <div className=" mt-2 mb-0">
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={isSubmitting}
                                  >
                                    Setup
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      )}
                    </Formik>
                  </div>
                </Animated>
              </div>
            )}

            {this.props.cS.active_branch.branch_id[0].environment ===
              "production" && (
              <div className="col-lg-6">
                <Animated
                  animationInDuration={850}
                  animationOutDuration={850}
                  animationOut="fadeOut"
                  isVisible={
                    this.props.cS.active_branch.branch_id[0].environment ===
                    "production"
                  }
                  animationInDelay={700}
                  animationIn="fadeIn"
                  className="card"
                >
                  <div className="card-header">
                    <div className="card-title">
                      <Trans i18nKey="production_settings">
                        Production Settings
                      </Trans>
                    </div>
                  </div>
                  <div className="card-body">
                    <Formik
                      initialValues={{
                        is_test_mode_active: this.props.cS.active_branch
                          .branch_id[0].is_test_mode_active,
                      }}
                      onSubmit={(values, { setSubmitting }) => {
                        const headers = global.header_api;
                        const body = values;
                        // console.log(body);

                        axios
                          .post(
                            global.url_api +
                              "/rest/data/branch/" +
                              String(
                                this.props.cS.active_branch.branch_id[0].id
                              ) +
                              "/update",
                            body,
                            {
                              headers: headers,
                            }
                          )
                          .then((res) => {
                            this.props.handleCS(res.data);
                            window.swal(
                              "Congratulations!",
                              "Settings successfully updated. Remember to make a reload of your instance to apply the changes.",
                              "success"
                            );
                          })
                          .catch(function (error) {
                            window.swal({
                              title: "Alert",
                              text:
                                "Ops! Something went wrong:\n\n" +
                                error.toJSON().message,
                              type: "error",
                            });
                            // console.log(error.toJSON());
                            this.setState({ err: true });
                          });

                        setSubmitting(false);

                        // debug purpose
                        // setTimeout(() => {
                        //     alert(JSON.stringify(values, null, 2));
                        //     setSubmitting(false);
                        // }, 400);
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        /* and other goodies */
                      }) => (
                        <div>
                          <form onSubmit={handleSubmit}>
                            <div>
                              <div className="checkbox">
                                <div className="custom-checkbox custom-control">
                                  <Field
                                    className="custom-control-input"
                                    name="is_test_mode_active"
                                    component="input"
                                    type="checkbox"
                                    id="checkbox-1"
                                    checked={values.is_test_mode_active}
                                  />
                                  <label
                                    htmlFor="checkbox-1"
                                    className="custom-control-label"
                                  >
                                    Enable Testing Mode?
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12 col-lg-12">
                                <div className=" mt-2 mb-0">
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={isSubmitting}
                                  >
                                    Setup
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      )}
                    </Formik>
                  </div>
                </Animated>
              </div>
            )}
          </div>
        ) : (
          <LoadingSpinner err={this.state.err} />
        )}
      </React.Fragment>
    );
  }
}

export default BranchSettings;
