import React from "react";
import ReactDOM from "react-dom";
import PageMain from "./components/PageMain";
import i18n from "./config/i18next";
import { I18nextProvider } from "react-i18next";
import "./index.css";
ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <PageMain />
  </I18nextProvider>,
  document.getElementById("root")
);
