import React from "react";
// import global from "../config";
import BranchActions from "./BranchActions";
import { Link } from "react-router-dom";
import BranchEnvironment from "./BranchEnvironment";
import BranchStatus from "./BranchStatus";
import BranchActionsModal from './BranchActionsModal';

class BranchesTableRow extends React.Component {
  render() {
    return (
      <React.Fragment>
        <tr>
          <td>
            <Link
              to={
                this.props.branchId.environment !== "not_assigned"
                  ? "/branch/" + String(this.props.branchId.id)
                  : "#"
              }
            >
              <i className="fe fe-git-branch"></i>
              {this.props.branchId.name}
            </Link>
          </td>
          <td>
            <BranchEnvironment environment={this.props.branchId.environment} />
          </td>
          <td>
            {this.props.branchId.environment !== "not_assigned" && (
              <BranchStatus branchId={this.props.branchId.id} />
            )}
          </td>
          <td>
            <BranchActions
              branchId={this.props.branchId}
              cS={this.props.cS}
              handleCS={this.props.handleCS}
            />
          </td>
        </tr>
      </React.Fragment>
    );
  }
}

export default BranchesTableRow;
