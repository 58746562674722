/* eslint-disable */
import React from "react";
import global from "../config";
import LoadingSpinner from "./LoadingSpinner";
import "../config";
import "./ProjectBuilds.css";
const axios = require("axios").default;
axios.defaults.withCredentials = true;

import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import CustomDropdown from "./CustomDropdown";
import { Animated } from "react-animated-css";

class ProjectBuilds extends React.Component {
  constructor(props) {
    super(props);
    this.props.handleCS({
      active_menu: "project_builds",
    });
    this.state = {
      updated: false,
      countCommit: 0,
    };
  }

  componentDidMount() {
    const headers = global.header_api;
    this.commitCounter;
    axios
      .get(
        global.url_api +
          "/rest/data/project/" +
          String(this.props.match.params.pid),
        {
          headers: headers,
        }
      )
      .then((res) => {
        const data = res.data;
        this.setState({ data: data });
        this.props.handleCS({
          active_project: data,
          active_branch: null,
        });
        this.setState({ updated: true });
      })
      .catch(function (error) {
        console.log(error.toJSON());
        this.setState({ err: true });
      });
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-header">
          <h3 className="page-title">
            <i className="fe fe-box" />
            {this.props.cS.active_project && this.state.updated ? (
              <Trans i18nKey="projects_builds">Projects Builds</Trans>
            ) : (
              "Loading..."
            )}
          </h3>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/project/list/">
                <Trans i18nKey="projects">Projects</Trans>
              </Link>
            </li>
            {this.props.cS.active_project && this.state.updated && (
              <li className="breadcrumb-item">
                <Link
                  to={
                    "/project/" +
                    String(this.props.cS.active_project.project_id[0].id)
                  }
                >
                  {this.props.cS.active_project.project_id[0].name}
                </Link>
              </li>
            )}
            <li className="breadcrumb-item active" aria-current="page">
              Builds
            </li>
          </ol>
        </div>
        {this.props.cS.active_project && this.state.updated ? (
          <Animated
            animationInDuration={800}
            animationOutDuration={800}
            animationOut="fadeOut"
            isVisible={true}
            animationInDelay={500}
            animationIn="fadeIn"
          >
            <div className="table_container_wrapper">
              <div className="table_container">
                <table className="table_build">
                  <tbody>
                    {this.props.cS.active_project.build_ids.map(
                      (branch, index) => {
                        return (
                          <tr key={index}>
                            <td className="branch_title">
                              {this.props.cS.active_project.project_branch_ids[
                                index
                              ].environment !== "not_assigned"
                                ? branch.branch_obj[0].name
                                : null}
                            </td>
                            {branch.last_build_ids.map((build, index2) => {
                              var hours = parseInt(
                                build.commit_ids[0].create_date.substring(
                                  11,
                                  13
                                )
                              );

                              var i = 0;
                              var res = [];
                              while (i < 5) {
                                i++;

                                res.push(
                                  <td
                                    key={index + i}
                                    className={
                                      i % 2 === 0 ? "nightmode" : "daymode"
                                    }
                                  >
                                    {i % 2 === 0 ? (
                                      <i className="fa fa-thumbs-down" />
                                    ) : (
                                      <i className="fa fa-thumbs-up" />
                                    )}
                                    {/*<CustomDropdown />*/}
                                    {i % 2 !== 0 ? (
                                      <i className="far fa-moon"></i>
                                    ) : (
                                      <i className="far fa-sun"></i>
                                    )}
                                    {build.commit_ids.length > 0 &&
                                      build.commit_ids[0].message}{" "}
                                    <br />
                                    <Trans i18nKey="created_on">
                                      Created on{" "}
                                    </Trans>
                                    {build.create_date.substring(0, 16)}
                                    <br />
                                    {build.commit_ids.length > 0 &&
                                      build.commit_ids[0].name}
                                  </td>
                                );
                              }

                              return res;
                            })}
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </Animated>
        ) : (
          <LoadingSpinner err={this.state.err} />
        )}
      </React.Fragment>
    );
  }
}

export default ProjectBuilds;
{
  /* <React.Fragment>
<div className="row">
  <div className="col-md-12">
    <div className="card">
      {this.props.cS.active_project.build_ids.map(
        (branch, index) => {
          return (
            <div key={index}>
              {console.log(JSON.stringify(branch))}
              <div className="row no-gutters">
                <div className="col-lg-3 col-md-6 border-right-branch-build">
                  <div className="card-body text-center">
                    <div className="dash1-branch-build">
                      <h4 className="text-primary">
                        {branch.branch_obj[0].name}
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 border-right-branch-build">
                  {branch.last_build_ids.map((build, index2) => {
                    return (
                      <div
                        key={index2}
                        className="card-body text-center"
                      >
                        <div className="commit_Message">
                          {build.commit_ids.length > 0 &&
                            build.commit_ids[
                              build.commit_ids.length - 1
                            ]["message"]}
                        </div>
                        <div>
                          <Trans i18nKey="created_on">
                            Created on
                          </Trans>{" "}
                          {build.create_date}
                        </div>

                        <div>
                          {build.commit_ids.length > 0 &&
                            build.commit_ids[
                              build.commit_ids.length - 1
                            ].name}
                        </div>
                        <div>

                        </div>
                        <div className="tags">
                          {build.active && (
                            <div className="tag">
                              odoo
                              <Link
                                to={"https://" + build.build_url}
                                target="_blank"
                              >
                                <span className="tag-addon tag-success">
                                  connect
                                </span>
                              </Link>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        }
      )}{" "}
    </div>
  </div>
</div>
</React.Fragment> */
}
