import axios from "axios";
axios.defaults.withCredentials = true;
const global = {
  login: function (values) {
    axios
      .post(
        this.url_api + "/rws/session/authenticate",
        {
          params: {
            db: this.db_name,
            login: values.email,
            password: values.password,
          },
        },
        {
          headers: this.header_api,
        }
      )
      .then((res) => {
        const data = res.data;

        console.log("auth: " + JSON.stringify(data));
        if ("error" in data)
          switch (data.error.data.message) {
            case "Access denied": {
              window.swal(
                "Access Denied",
                "Incorrect Email or Password, please retry or contact us!",
                "error"
              );
              break;
            }
            default: {
              window.swal({
                title: "Alert",
                text: "Ops! Something went wrong:\n\n" + data.error.message,
                type: "error",
              });
              break;
            }
          }

        axios
          .post(
            this.url_api + "/rws/session/get_session_info",
            { params: {} },
            {
              headers: this.header_api,
            }
          )
          .then((res) => {
            const data = res.data;
            // console.log("sess_info: " + JSON.stringify(data));

            if ("error" in data) {
              // console.log("Errore: " + JSON.stringify(data));
            } else {
              window.swal({
                title: "Success!",
                text: "Greetings! You will be redirect in the dashboard soon!",
                type: "success",
              });
              window.location = "/";
            }
          })
          .catch(function (error) {
            // console.log(error);
          });
      })
      .catch(function (error) {
        // console.log(error);
      });
  },
  logout: function () {
    axios
      .get(this.url_api + "/rws/session/logout", {
        headers: this.header_api,
      })
      .then((res) => {
        // const data = res.data;
        // console.log('sess_info: ' + JSON.stringify(data));
      })
      .catch(function (error) {
        // console.log(error);
      });
  },
  // url_api: "http://127.0.0.1:8069",
  url_api: "https://api.odoostack.dev",
  header_api: "",
  db_name: "odoostack",
  // db_name: "odoostack_2021-05-02",
  ws_url: "api.odoostack.dev",
};
export default global;

/* module.exports = global.config = {
  url_api: "https://backend.odoostack.dev",
  header_api: {
    // 'X-Openerp-Session-Id': '7ddf36d4c01aaf3925a7c45aed2c64c1d2aae710'
  },
  db_name: "odoostack_2020-07-10",
};
 */
