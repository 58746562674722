import React from "react";
import global from "../config";
import LoadingSpinner from "./LoadingSpinner";
import UserPublicKey from "./UserPublicKey";
import BranchesTable from "./BranchesTable";
import { Link } from "react-router-dom";
import GitcommandRow from "./GitcommandRow";
import { Trans } from "react-i18next";
import { Animated } from "react-animated-css";
import BranchActionsModal from "./BranchActionsModal";

const axios = require("axios").default;
axios.defaults.withCredentials = true;

class Project extends React.Component {
  constructor(props) {
    super(props);
    this.props.handleCS({
      active_menu: "project",
    });
    this.state = {
      updated: false,
    };
  }

  componentDidMount() {
    const headers = global.header_api;
    axios
      .get(
        global.url_api +
          "/rest/data/project/" +
          String(this.props.match.params.pid),
        {
          headers: headers,
        }
      )
      .then((res) => {
        const data = res.data;
        this.props.handleCS({
          active_project: data,
          active_branch: null,
        });
        this.setState({ updated: true });
      })
      .catch(function (error) {
        console.log(error.toJSON());
        this.setState({ err: true });
      });
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-header">
          <h3 className="page-title">
            {this.props.cS.active_project && this.state.updated
              ? this.props.cS.active_project.project_id[0].name
              : "Loading..."}
          </h3>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/project/list/">
                <Trans i18nKey="projects">Projects</Trans>
              </Link>
            </li>
            {this.props.cS.active_project && this.state.updated && (
              <li className="breadcrumb-item active" aria-current="page">
                {this.props.cS.active_project.project_id[0].name}
              </li>
            )}
          </ol>
        </div>

        {this.props.cS.active_project && this.state.updated ? (
          <div>
            <Animated
              animationInDuration={850}
              animationOutDuration={850}
              animationOut="fadeOut"
              isVisible={this.props.cS.active_project && this.state.updated}
              animationInDelay={200}
              animationIn="fadeIn"
              className="row"
            >
              <div className="col-md-12 col-lg-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12 col-lg-12">
                        <UserPublicKey
                          userPublicKey={
                            this.props.cS.active_project.project_git_id[0]
                              .public_key
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Animated>
            {/*<Animated*/}
            {/*  animationInDuration={850}*/}
            {/*  animationOutDuration={850}*/}
            {/*  animationOut="fadeOut"*/}
            {/*  isVisible={this.props.cS.active_project && this.state.updated}*/}
            {/*  animationInDelay={400}*/}
            {/*  animationIn="fadeIn"*/}
            {/*>*/}
            {/*  <GitcommandRow cS={this.props.cS} />*/}
            {/*</Animated>*/}
            <Animated
              animationInDuration={850}
              animationOutDuration={850}
              animationOut="fadeOut"
              isVisible={this.props.cS.active_project && this.state.updated}
              animationInDelay={600}
              animationIn="fadeIn"
            >
              <div className="row" t-att-data-ns="project_id.namespace">
                <div className="col-md-12 col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title">
                        <i className="fe fe-git-branch"></i> BRANCHES
                      </div>
                    </div>

                    <div className="card-body pt-0">
                      <BranchesTable
                        projectId={this.props.cS.active_project.project_id[0]}
                        projectBranchIds={
                          this.props.cS.active_project.project_branch_ids
                        }
                        cS={this.props.cS}
                        handleCS={this.props.handleCS}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Animated>
          </div>
        ) : (
          <LoadingSpinner err={this.state.err} />
        )}

      </React.Fragment>
    );
  }
}

export default Project;
