import React from "react";
import global from "../config";
import { Trans } from "react-i18next";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import { Animated } from "react-animated-css";
// import { Redirect } from "react-router-dom";

const axios = require("axios").default;
axios.defaults.withCredentials = true;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.props.handleCS({
      active_menu: "dashboard",
      active_project: null,
      active_branch: null,
    });
    this.state = {
      updated: false,
      user_project_len: null,
      user_branch_len: null,
      user_active_build_len: null,
    };
  }

  componentDidMount() {
    axios
      .get(global.url_api + `/rest/data/user/`, {
        headers: global.header_api,
      })
      .then((res) => {
        const data = res.data;
        this.props.handleCS({
          active_user: data,
        });
        this.setState({ updated: true });
        this.setState({
          updated: true,
          user_project_len: data.user_project_len,
          user_branch_len: data.user_branch_len,
          user_active_build_len: data.user_active_build_len,
        });
        /*  console.log(JSON.stringify(data)); */
      })
      .catch(function (error) {
        // console.log(error);
        if (error.response.status === 401) {
          this.props.handleCS({
            logged_in: false,
          });
        }
      });
  }

  render() {
    if (this.state.updated)
      return (
        <React.Fragment>
          <div className="page-header">
            <h3 className="page-title">
              {/*<i className="fe fe-grid" />{" "}*/}
              {this.props.cS.active_user && this.state.updated
                ? "Dashboard"
                : "Loading..."}
            </h3>
            <ol className="breadcrumb">
              <li className="breadcrumb-item active" aria-current="page">
                Dashboard
              </li>
            </ol>
          </div>
          <Animated
            animationInDuration={1000}
            animationOutDuration={1000}
            animationOut="fadeOut"
            isVisible={this.props.cS.active_user && this.state.updated}
            animationInDelay={300}
            animationIn="fadeInUp"
            className="row"
          >
            <div className="col-md-12">
              <div className="card">
                <div>
                  <div className="row no-gutters">
                    <div className="col-lg-4 col-md-4 border-right">
                      <div className="card-body text-center">
                        <div className="dash3">
                          <h5 className="text-muted">
                            <Trans i18nKey="projects">Projects</Trans>
                          </h5>
                          <h4 className="counter text-primary">
                            {!this.state.user_project_len && !this.state.updated
                              ? "Loading..."
                              : this.state.user_project_len}
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 border-right">
                      <div className="card-body text-center">
                        <div className="dash3">
                          <h5 className="text-muted">Branches</h5>
                          <h4 className="counter text-success">
                            {this.state.user_branch_len && !this.state.updated
                              ? "Loading..."
                              : this.state.user_branch_len}
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 border-right">
                      <div className="card-body text-center">
                        <div className="dash3">
                          <h5 className="text-muted">
                            <Trans i18nKey="active_instances">
                              Active Instances
                            </Trans>
                          </h5>
                          <h4 className="counter text-danger">
                            {!this.state.user_active_build_len &&
                            !this.state.updated
                              ? "Loading..."
                              : this.state.user_active_build_len}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Animated>
          <Animated
            animationInDuration={1000}
            animationOutDuration={1000}
            animationOut="fadeOut"
            isVisible={this.props.cS.active_user && this.state.updated}
            animationInDelay={700}
            animationIn="fadeInUp"
            className="row"
          >
            <div className="col-md-6">
              <div style={{ padding: "20px" }} className="card">
                <TwitterTimelineEmbed
                  sourceType="profile"
                  screenName="Odoostack"
                  options={{height: 1700}}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div style={{ padding: "20px" }} className="card">
                <TwitterTimelineEmbed
                  sourceType="profile"
                  screenName="Odoo"
                  options={{height: 1700}}
                />
              </div>
            </div>
          </Animated>
        </React.Fragment>
      );
    else return null;
  }
}

export default Dashboard;
