import React from "react";
import { Trans } from "react-i18next";
// import global from "../config";
import BranchesTableRow from "./BranchesTableRow";
/* eslint-disable */
class BranchesTable extends React.Component {
  render() {
    return (
      <React.Fragment>
         
        <div className="table-responsive">
          <table id="example-2" className="table table-striped table-bordered">
            <thead>
              <tr>
                <th className="wd-15p border-bottom-0">Branch</th>
                <th className="wd-15p border-bottom-0">
                  <Trans i18nKey="environment">Environment</Trans>
                </th>
                <th className="wd-15p border-bottom-0">
                  <Trans i18nKey="status">Status</Trans>
                </th>
                <th className="wd-15p border-bottom-0">
                  <Trans i18nKey="actions">Actions</Trans>
                </th>
              </tr>
            </thead>
            <tbody>
              {this.props.projectBranchIds &&
                this.props.projectBranchIds.map((branch, index) => {
                  return (
                    <BranchesTableRow
                      key={index}
                      index={index}
                      branchId={branch}
                      cS={this.props.cS}
                      handleCS={this.props.handleCS}
                     setSelectedBranch={this.props.setSelectedBranch}
                    />
                  );
                })}
            </tbody>
          </table>
        </div>
       
      </React.Fragment>
    );
  }
}

export default BranchesTable;
