import React from "react";
import global from "../config";
import LoadingSpinner from "./LoadingSpinner";
import BranchStatus from "./BranchStatus";
import BranchEnvironment from "./BranchEnvironment";
import BranchDetails from "./BranchDetails";
import BranchLogViewer from "./BranchLogViewer";
import BranchActions from "./BranchActions";
import "./Branch.css";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { Animated } from "react-animated-css";
import SnapshotTable from './SnapshotTable';

const axios = require("axios").default;
axios.defaults.withCredentials = true;

class BranchLogs extends React.Component {
  constructor(props) {
    super(props);
    this.props.handleCS({
      active_menu: "branch_logs",
    });
    this.state = {
      updated: false,
      health_status: false,
    };
  }

  componentDidMount() {
    const headers = global.header_api;
    axios
      .get(
        global.url_api +
          "/rest/data/branch/" +
          String(this.props.match.params.bid),
        {
          headers: headers,
        }
      )
      .then((res) => {
        const data = res.data;
        this.props.handleCS(data);
        this.setState({ updated: true });
      })
      .catch(function (error) {
        console.log(error.toJSON());
        this.setState({ err: true });
      });
  }
  swap_status = (type) => {
    this.setState({ health_status: type });
  };
  render() {
    return (
      <React.Fragment>
        <div className="page-header">
          <h3 className="page-title">
            {/*<i className="fe fe-box" />{" "}*/}
            {this.props.cS.active_branch && this.state.updated ? (
              <BranchEnvironment
                prefix={this.props.cS.active_branch.branch_id[0].name + " - "}
                environment={
                  this.props.cS.active_branch.branch_id[0].environment
                }
              />
            ) : (
              "Loading..."
            )}
          </h3>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/project/list/">
                <Trans i18nKey="projects">Projects</Trans>
              </Link>
            </li>
            {this.props.cS.active_project && this.state.updated && (
              <li className="breadcrumb-item">
                <Link
                  to={
                    "/project/" +
                    String(this.props.cS.active_project.project_id[0].id)
                  }
                >
                  {this.props.cS.active_project.project_id[0].name}
                </Link>
              </li>
            )}
            {this.props.cS.active_branch && this.state.updated && (
              <li className="breadcrumb-item">
                <Link
                  to={
                    "/branch/" +
                    String(this.props.cS.active_branch.branch_id[0].id)
                  }
                >
                  {this.props.cS.active_branch.branch_id[0].name}
                </Link>
              </li>
            )}
            <li className="breadcrumb-item active" aria-current="page">
              {" "}
              <Trans i18nKey="logs">Logs</Trans>
            </li>
          </ol>
        </div>

        {this.props.cS.active_branch && this.state.updated ? (
          <div>
            <div className="row">
              <div className="col-xl-12 col-md-12">
                <Animated
                  animationInDuration={1050}
                  animationOutDuration={1050}
                  animationOut="fadeOut"
                  isVisible={this.props.cS.active_branch && this.state.updated}
                  animationInDelay={400}
                  animationIn="fadeIn"
                  className="card"
                >
                  <div className="card-header-loader">
                    {this.props.cS.active_branch.branch_id[0].environment !==
                      "not_assigned" && (
                      <BranchStatus
                        branchId={this.props.cS.active_branch.branch_id[0].id}
                        swap_status={this.swap_status}
                      />
                    )}
                  </div>
                  <div className="card-header-loader">
                    <BranchActions
                      branchId={this.props.cS.active_branch.branch_id[0]}
                      cS={this.props.cS}
                      handleCS={this.props.handleCS}
                    />
                  </div>
                  <div style={{ height: "1000px" }} className="card-body p-6">
                      <BranchLogViewer
                        health_status={this.state.health_status}
                        branchId={
                          this.props.cS.active_branch.branch_id[0].id
                        }
                      />
                  </div>
                </Animated>
              </div>
            </div>
          </div>
        ) : (
          <LoadingSpinner err={this.state.err} />
        )}
      </React.Fragment>
    );
  }
}

export default BranchLogs;
