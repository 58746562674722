import React, { useState, useEffect } from "react";
import { Trans } from "react-i18next";

const BranchActionsModal = ({ cS }) => {
  const [description, setDescription] = useState("");
  useEffect(() => {
    if (cS.modal) {
      setDescription("");
    }
  }, [cS]);

  return (
    <div
      className="modal fade"
      id="branchActionsCreateSnapshotModal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="create-snapshot-modal">
              <Trans i18nKey="create_snapshot">Create snapshot</Trans>
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form>
              <div className="form-group">
                <label htmlFor="recipient-name" className="form-control-label">
                  <Trans i18nKey="description">Description</Trans>
                </label>
                <input
                  type="text"
                  placeholder="Inserisci una descrizione (opzionale)... "
                  className="form-control"
                  id="new-snapshot-name"
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              data-dismiss="modal"
              onClick={() => (cS.modal ? cS.modal.onClick(description) : "")}
            >
              Create
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BranchActionsModal;
