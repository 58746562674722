import React, { Component } from "react";

export default class CustomDropdown extends Component {
  render() {
    return (
      <div
        className="btn-group mt-2 mb-2"
        style={{
          position: "absolute",
          top: "0px",
          right: "5px",
          border: "none",
        }}
      >
        <button
          type="button"
          className="btn btn-success btn-sm dropdown-toggle"
          data-toggle="dropdown"
          aria-expanded="false"
          style={{
            width: "auto",
            background: "#7c7bad",
            padding: "0px 5px",
          }}
        ></button>
        <ul
          className="dropdown-menu"
          role="menu"
          x-placement="bottom-start"
          style={{
            position: "absolute",
            willChange: "transform",
            top: 0,
            left: 0,
            border: "none",
            transform: "translate3d(0px, 26px, 0px)",
          }}
        >
          <li className="dropdown-plus-title">
            <b className="fas fa-angle-up" aria-hidden="true" />
          </li>
          <li>
            <a href="#">Action</a>
          </li>
          <li>
            <a href="#">Another action</a>
          </li>
          <li>
            <a href="#">Something else here</a>
          </li>
          <li className="divider" />
          <li>
            <a href="#">Separated link</a>
          </li>
        </ul>
      </div>
    );
  }
}
