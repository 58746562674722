import React from "react";
import global from "../config";
import LoadingSpinner from "./LoadingSpinner";
import { Formik, Field } from "formik";
import "../config";
import { Trans } from "react-i18next";

const axios = require("axios").default;
axios.defaults.withCredentials = true;

import { Link } from "react-router-dom";
import { Animated } from "react-animated-css";

// import swal from 'sweetalert';

class ProjectSettings extends React.Component {
  constructor(props) {
    super(props);
    this.props.handleCS({
      active_menu: "project_settings",
    });
    this.state = {
      updated: false,
    };
  }

  componentDidMount() {
    const headers = global.header_api;
    axios
      .get(
        global.url_api +
          "/rest/data/project/" +
          String(this.props.match.params.pid),
        {
          headers: headers,
        }
      )
      .then((res) => {
        const data = res.data;
        this.props.handleCS({
          active_project: data,
          active_branch: null,
        });
        this.setState({ updated: true });
      })
      .catch(function (error) {
        console.log(error.toJSON());
        this.setState({ err: true });
      });
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-header">
          <h3 className="page-title">
            <i className="fe fe-box" />{" "}
            {this.props.cS.active_project && this.state.updated ? (
              <Trans i18nKey="projects_settings">Project Settings</Trans>
            ) : (
              "Loading..."
            )}
          </h3>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="dashboard/project/list/">
                <Trans i18nKey="projects">Projects</Trans>
              </Link>
            </li>
            {this.props.cS.active_project && this.state.updated && (
              <li className="breadcrumb-item">
                <Link
                  to={
                    "/project/" +
                    String(this.props.cS.active_project.project_id[0].id)
                  }
                >
                  {this.props.cS.active_project.project_id[0].name}
                </Link>
              </li>
            )}
            <li className="breadcrumb-item active" aria-current="page">
              <Trans i18nKey="settings">Settings</Trans>
            </li>
          </ol>
        </div>

        {this.props.cS.active_project && this.state.updated ? (
          <div className="row">
            <div className="col-lg-6">
              <Animated
                animationInDuration={850}
                animationOutDuration={850}
                animationOut="fadeOut"
                isVisible={this.props.cS.active_project && this.state.updated}
                animationInDelay={300}
                animationIn="fadeIn"
                className="card"
              >
                <div className="card-header">
                  <div className="card-title">
                    <Trans i18nKey="production_env">
                      Production Environment
                    </Trans>
                  </div>
                </div>
                <div className="card-body">
                  <Formik
                    initialValues={{
                      domain_prod:
                        this.props.cS.active_project.project_id[0]
                          .domain_prod === false
                          ? ""
                          : this.props.cS.active_project.project_id[0]
                              .domain_prod,
                      domain_ssl_redirect: this.props.cS.active_project
                        .project_id[0].domain_ssl_redirect,
                      ssl_private_key: this.props.cS.active_project
                        .project_id[0].ssl_private_key
                        ? this.props.cS.active_project.project_id[0]
                            .ssl_private_key
                        : "",
                      ssl_cert: this.props.cS.active_project.project_id[0]
                        .ssl_cert
                        ? this.props.cS.active_project.project_id[0].ssl_cert
                        : "",
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      const headers = global.header_api;
                      const body = values;
                      // console.log(body);

                      axios
                        .post(
                          global.url_api +
                            "/rest/data/project/" +
                            String(
                              this.props.cS.active_project.project_id[0].id
                            ) +
                            "/update",
                          body,
                          {
                            headers: headers,
                          }
                        )
                        .then((res) => {
                          this.props.handleCS({
                            active_project: res.data,
                            active_branch: null,
                          });
                          window.swal(
                            "Congratulations!",
                            "Settings successfully updated",
                            "success"
                          );
                        })
                        .catch(function (error) {
                          window.swal({
                            title: "Alert",
                            text:
                              "Ops! Something went wrong:\n\n" +
                              error.toJSON().message,
                            type: "error",
                          });
                          // console.log(error.toJSON());
                          this.setState({ err: true });
                        });

                      setSubmitting(false);

                      // debug purpose
                      // setTimeout(() => {
                      //     alert(JSON.stringify(values, null, 2));
                      //     setSubmitting(false);
                      // }, 400);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                    }) => (
                      <div>
                        <form onSubmit={handleSubmit}>
                          <div>
                            <div className="form-group">
                              <label className="form-label">
                                <Trans i18nKey="domain_name">Domain Name</Trans>
                              </label>
                              <input
                                type="text"
                                name="domain_prod"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.domain_prod}
                                className={
                                  errors.domain_prod && touched.domain_prod
                                    ? "form-control is-invalid state-invalid"
                                    : "form-control"
                                }
                                placeholder="Ex. odoostack.dev"
                              />
                              {errors.domain_prod && touched.domain_prod && (
                                <div className="invalid-feedback">
                                  {errors.domain_prod}
                                </div>
                              )}
                            </div>
                            <div className="checkbox">
                              <div className="custom-checkbox custom-control">
                                <Field
                                  className="custom-control-input"
                                  name="domain_ssl_redirect"
                                  component="input"
                                  type="checkbox"
                                  id="checkbox-1"
                                  checked={values.domain_ssl_redirect}
                                />
                                <label
                                  htmlFor="checkbox-1"
                                  className="custom-control-label"
                                >
                                  <Trans i18nKey="force_ssl_redirect">
                                    {" "}
                                    Force SSL Redirect?
                                  </Trans>
                                </label>
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="form-label">
                                <Trans i18nKey="domain_ssl_private_key">
                                  Domain - SSL Private Key
                                </Trans>
                              </label>
                              <textarea
                                name="ssl_private_key"
                                rows="6"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.ssl_private_key}
                                className={
                                  errors.ssl_private_key &&
                                  touched.ssl_private_key
                                    ? "form-control is-invalid state-invalid"
                                    : "form-control"
                                }
                                placeholder="Ex. odoostack.dev"
                              />
                              {errors.ssl_private_key &&
                                touched.ssl_private_key && (
                                  <div className="invalid-feedback">
                                    {errors.ssl_private_key}
                                  </div>
                                )}
                            </div>
                            <div className="form-group">
                              <label className="form-label">
                                <Trans i18nKey="domain_ssl_certificate">
                                  Domain - SSL Certificate
                                </Trans>
                              </label>
                              <textarea
                                name="ssl_cert"
                                rows="6"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.ssl_cert}
                                className={
                                  errors.ssl_cert && touched.ssl_cert
                                    ? "form-control is-invalid state-invalid"
                                    : "form-control"
                                }
                                placeholder="Ex. odoostack.dev"
                              />
                              {errors.ssl_cert && touched.ssl_cert && (
                                <div className="invalid-feedback">
                                  {errors.ssl_cert}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 col-lg-12">
                              <div className=" mt-2 mb-0">
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                  disabled={isSubmitting}
                                >
                                  Setup
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                  </Formik>
                </div>
              </Animated>
            </div>
            <div className="col-lg-6">
              <Animated
                animationInDuration={850}
                animationOutDuration={850}
                animationOut="fadeOut"
                isVisible={this.props.cS.active_project && this.state.updated}
                animationInDelay={300}
                animationIn="fadeIn"
                className="card"
              >
                <div className="card-header">
                  <div className="card-title">
                    <Trans i18nKey="staging_env">
                      Staging Environment
                    </Trans>
                  </div>
                </div>
                <div className="card-body">
                  <Formik
                    initialValues={{
                      domain_prod:
                        this.props.cS.active_project.project_id[0]
                          .domain_prod === false
                          ? ""
                          : this.props.cS.active_project.project_id[0]
                              .domain_prod,
                      domain_ssl_redirect: this.props.cS.active_project
                        .project_id[0].domain_ssl_redirect,
                      ssl_private_key: this.props.cS.active_project
                        .project_id[0].ssl_private_key
                        ? this.props.cS.active_project.project_id[0]
                            .ssl_private_key
                        : "",
                      ssl_cert: this.props.cS.active_project.project_id[0]
                        .ssl_cert
                        ? this.props.cS.active_project.project_id[0].ssl_cert
                        : "",
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      const headers = global.header_api;
                      const body = values;
                      // console.log(body);

                      axios
                        .post(
                          global.url_api +
                            "/rest/data/project/" +
                            String(
                              this.props.cS.active_project.project_id[0].id
                            ) +
                            "/update",
                          body,
                          {
                            headers: headers,
                          }
                        )
                        .then((res) => {
                          this.props.handleCS({
                            active_project: res.data,
                            active_branch: null,
                          });
                          window.swal(
                            "Congratulations!",
                            "Settings successfully updated",
                            "success"
                          );
                        })
                        .catch(function (error) {
                          window.swal({
                            title: "Alert",
                            text:
                              "Ops! Something went wrong:\n\n" +
                              error.toJSON().message,
                            type: "error",
                          });
                          // console.log(error.toJSON());
                          this.setState({ err: true });
                        });

                      setSubmitting(false);

                      // debug purpose
                      // setTimeout(() => {
                      //     alert(JSON.stringify(values, null, 2));
                      //     setSubmitting(false);
                      // }, 400);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                    }) => (
                      <div>
                        <form onSubmit={handleSubmit}>
                          <div>
                            <div className="form-group">
                              <label className="form-label">
                                <Trans i18nKey="sql_automations">SQL Automations</Trans>
                              </label>
                              <input
                                type="text"
                                name="sql_automations"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.sql_automations}
                                className={
                                  errors.sql_automations && touched.sql_automations
                                    ? "form-control is-invalid state-invalid"
                                    : "form-control"
                                }
                                placeholder=""
                              />
                              {errors.sql_automations && touched.sql_automations && (
                                <div className="invalid-feedback">
                                  {errors.sql_automations}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 col-lg-12">
                              <div className=" mt-2 mb-0">
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                  disabled={isSubmitting}
                                >
                                  Setup
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                  </Formik>
                </div>
              </Animated>
            </div>
          </div>
        ) : (
          <LoadingSpinner err={this.state.err} />
        )}
      </React.Fragment>
    );
  }
}

export default ProjectSettings;
