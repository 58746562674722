import React from "react";
import global from "../config";
import { Formik } from "formik";
import "./Login.css";
const axios = require("axios").default;
axios.defaults.withCredentials = true;

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.props.handleCS({
      logged_in: false,
    });
    this.state = { visibility: false };
  }

  render() {
    return (
      <React.Fragment>
        <div className="custompage">
          <div className="custom-content  mt-0">
            <div className="row">
              <div className="col-lg-8 d-block mx-auto">
                <div className="row">
                  <div className="col-md-12">
                    <img
                      src="/static/images/brand/logo.png"
                      className="header-brand-img mb-2 mt-2 mt-lg-0 "
                      alt="logo"
                    />
                    <h3 className="text-center">Login to your Account</h3>
                    <Formik
                      initialValues={{
                        email: "",
                        password: "",
                      }}
                      validate={(values, actions) => {
                        const errors = {};
                        if (
                          !/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i.test(
                            values.email
                          )
                        ) {
                          errors.email = "*Email non valida";
                        }
                        if (values.password.length < 5) {
                          errors.password = "*Password non è valida";
                        }
                        if (errors.length > 0) {
                          actions.setSubmitting(true);
                        }
                        return errors;
                      }}
                      onSubmit={(values, actions) => {
                        actions.setSubmitting(false);
                        this.setState({ formresult: values });
                        // debug purpose
                        // setTimeout(() => {
                        //     alert(JSON.stringify(values, null, 2));
                        //     setSubmitting(false);
                        // }, 400);
                        global.login(values);
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        resetForm,
                        setErrors,
                        isSubmitting,
                        /* and other goodies */
                      }) => (
                        <div className="form_container">
                          <form onSubmit={handleSubmit}>
                            <div className="form-group">
                              <label
                                htmlFor="email"
                                className="form-label text-left"
                              >
                                Email:
                              </label>
                              <input
                                type="text"
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                placeholder="Inserisci l'email associata al tuo account"
                                className={
                                  errors.email && touched.email
                                    ? "form-control is-invalid state-invalid"
                                    : "form-control"
                                }
                              />
                              {errors.email && touched.email && (
                                <div className="invalid-feedback left">
                                  {errors.email}
                                </div>
                              )}
                            </div>
                            <div className="form-group">
                              <label
                                className="form-label text-left"
                                htmlFor="password"
                              >
                                Password:
                              </label>

                              <input
                                type={
                                  this.state.visibility ? "text" : "password"
                                }
                                name="password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                placeholder="Inserisci la tua password"
                                className={
                                  errors.password && touched.password
                                    ? "form-control is-invalid state-invalid"
                                    : "form-control"
                                }
                              />

                              {errors.password && touched.password && (
                                <div className="invalid-feedback left">
                                  {errors.password}
                                </div>
                              )}
                              <i
                                onClick={() =>
                                  this.setState({
                                    visibility: !this.state.visibility,
                                  })
                                }
                                className={
                                  this.state.visibility
                                    ? "far fa-eye-slash icon"
                                    : "far fa-eye icon"
                                }
                                id="togglePassword"
                              ></i>
                            </div>
                            <p></p>
                            <div className="text-left">
                              <button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className="btn btn-primary"
                                disabled={isSubmitting}
                              >
                                Log In
                              </button>
                              <button
                                type="button"
                                onClick={() => resetForm() && setErrors("", "")}
                                className="btn btn-danger"
                              >
                                Cancel
                              </button>
                            </div>
                            {/*<div className="text-center text-muted mt-3">*/}
                            {/*  Don't have account yet? <a href="#">Sign up</a>*/}
                            {/*</div>*/}
                          </form>
                        </div>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Login;
