/* eslint-disable */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en.json";
import it from "./locales/it.json";
var locales;
if (navigator.language === "it-IT") {
  locales = "it";
} else {
  locales = "en";
}
i18n.use(initReactI18next).init({
  resources: {
    en,
    it,
  },
  fallbackLng: locales,
  debug: false,
  ns: ["translations"],
  defaultNS: "translations",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    formatSeparator: ",",
  },
  react: {
    wait: true,
  },
});
export default i18n;
