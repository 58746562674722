import React from "react";
import global from "../config";
import { Redirect } from "react-router-dom";
// import { Link, Redirect } from "react-router-dom";
// import { Trans } from "react-i18next";

class Logout extends React.Component {
  componentDidMount() {
    global.logout();
  }

  render() {
    return <Redirect to="/login" />;
  }
}

export default Logout;
