import React from "react";
import global from "../config";
import Login from "./Login";
import Logout from "./Logout";
import Container from "./Container";
import Dashboard from "./Dashboard";
import ProjectList from "./ProjectList";
import ProjectInit from "./ProjectInit";
import Project from "./Project";
import Branch from "./Branch";
import ProjectSettings from "./ProjectSettings";
import BranchSettings from "./BranchSettings";
import ProjectBuilds from "./ProjectBuilds";
import BranchDeleteModal from "./BranchDeleteModal";
import BranchLogs from "./BranchLogs";
const axios = require("axios").default;
axios.defaults.withCredentials = true;

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

class PageMain extends React.Component {
  constructor(props) {
    super(props);
    this.handleCS = this.handleCS.bind(this);
    this.state = {
      active_menu: null,
      active_project: null,
      active_branch: null,
      active_user: null,
      selectedBranch: {},
      logged_in: false,
      first_login_check: false,
      menu_expand_project: true,
      menu_expand_branch: true,
    };
  }

  handleCS(toUpdate) {
    this.setState(toUpdate);
  }

  componentDidMount() {
    axios
      .post(
        global.url_api + "/rws/session/get_session_info",
        { params: {} },
        {
          headers: global.header_api,
        }
      )
      .then((res) => {
        const data = res.data;
        // console.log('sess_info: ' + JSON.stringify(data));

        if ("error" in data) {
          this.setState({
            logged_in: false,
            first_login_check: true,
          });
        }

        this.setState({
          logged_in: true,
          first_login_check: true,
        });
      })
      .catch(function (error) {
        /*  console.log(error);
        this.setState({
          logged_in: false,
          first_login_check: true,
        }); */
      });
  }

  render() {
    return (
      this.state.first_login_check && (
        <React.Fragment>
          <Router>
            <div>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={(props) =>
                    this.state.logged_in === true ? (
                      <Container
                        {...props}
                        handleCS={this.handleCS}
                        cS={this.state}
                        activeContainer={Dashboard}
                      />
                    ) : (
                      <Redirect to="/login" />
                    )
                  }
                />
                <Route
                  exact
                  path="/login"
                  render={(props) => (
                    <Login
                      {...props}
                      handleCS={this.handleCS}
                      cS={this.state}
                    />
                  )}
                />
                <Route exact path="/logout" render={(props) => <Logout />} />
                <Route
                  exact
                  path="/project/list"
                  render={(props) =>
                    this.state.logged_in === true ? (
                      <Container
                        {...props}
                        handleCS={this.handleCS}
                        cS={this.state}
                        activeContainer={ProjectList}
                      />
                    ) : (
                      <Redirect to="/login" />
                    )
                  }
                />
                <Route
                  exact
                  path="/project/:pid/init"
                  render={(props) =>
                    this.state.logged_in === true ? (
                      <Container
                        {...props}
                        handleCS={this.handleCS}
                        cS={this.state}
                        activeContainer={ProjectInit}
                      />
                    ) : (
                      <Redirect to="/login" />
                    )
                  }
                />
                <Route
                  exact
                  path="/project/:pid"
                  render={(props) =>
                    this.state.logged_in === true ? (
                      <Container
                        {...props}
                        handleCS={this.handleCS}
                        cS={this.state}
                        activeContainer={Project}
                      />
                    ) : (
                      <Redirect to="/login" />
                    )
                  }
                />
              </Switch>
              <Route
                exact
                path="/project/:pid/settings"
                render={(props) => (
                  <Container
                    {...props}
                    handleCS={this.handleCS}
                    cS={this.state}
                    activeContainer={ProjectSettings}
                  />
                )}
              />
              <Route
                exact
                path="/project/:pid/builds"
                render={(props) =>
                  this.state.logged_in === true ? (
                    <Container
                      {...props}
                      handleCS={this.handleCS}
                      cS={this.state}
                      activeContainer={ProjectBuilds}
                    />
                  ) : (
                    <Redirect to="/login" />
                  )
                }
              />
              <Route
                exact
                path="/branch/:bid"
                render={(props) =>
                  this.state.logged_in === true ? (
                    <Container
                      {...props}
                      handleCS={this.handleCS}
                      cS={this.state}
                      activeContainer={Branch}
                    />
                  ) : (
                    <Redirect to="/login" />
                  )
                }
              />
              <Route
                exact
                path="/branch/:bid/logs"
                render={(props) =>
                  this.state.logged_in === true ? (
                    <Container
                      {...props}
                      handleCS={this.handleCS}
                      cS={this.state}
                      activeContainer={BranchLogs}
                    />
                  ) : (
                    <Redirect to="/login" />
                  )
                }
              />
              <Route
                exact
                path="/branch/:bid/settings"
                render={(props) =>
                  this.state.logged_in === true ? (
                    <Container
                      {...props}
                      handleCS={this.handleCS}
                      cS={this.state}
                      activeContainer={BranchSettings}
                    />
                  ) : (
                    <Redirect to="/login" />
                  )
                }
              />
            </div>
          </Router>
          <BranchDeleteModal cS={this.state} handleCS={this.handleCS} />
        </React.Fragment>
      )
    );
  }
}

export default PageMain;
