import React from "react";
import global from "../config";
import { Link } from "react-router-dom";
import CustomSidebar from "./CustomSidebar";
import MenuIcon from "@material-ui/icons/Menu";
import ClearIcon from "@material-ui/icons/Clear";
import ToggleIcon from "material-ui-toggle-icon";
import IconButton from "@material-ui/core/IconButton";
const axios = require("axios").default;
axios.defaults.withCredentials = true;

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = { shouldOpen: false, width: window.innerWidth };
  }
  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    axios
      .get(global.url_api + `/rest/data/user/`, {
        headers: global.header_api,
      })
      .then((res) => {
        const data = res.data;
        this.props.handleCS({
          active_user: data,
        });
      })
      .catch(function (error) {
        console.log(error.toJSON());
        this.setState({ err: true });
      });
  }
  handleSide() {
    this.setState({ shouldOpen: !this.state.shouldOpen });
  }
  render() {
    return (
      <React.Fragment>
        {/* Navbar*/}
        <header className="app-header header">
          {/* Navbar Right Menu*/}
          <div className="container-fluid">
            <div className="d-flex">
              <a className="header-brand" href="/">
                <img
                  alt="logo"
                  className="header-brand-img"
                  src="/static/images/brand/logo.png"
                />
              </a>
              {/*<a aria-label="Hide Sidebar" className="app-sidebar__toggle" data-toggle="sidebar" href="#"/>*/}
              <div className="d-flex order-lg-2 ml-auto">
                <div className="d-none d-md-flex">
                  <a
                    href="#"
                    className="nav-link icon full-screen-link"
                    id="fullscreen-button"
                  >
                    <i className="fe fe-minimize " />
                  </a>
                </div>
                <div className="dropdown">
                  <a
                    className="nav-link pr-0 leading-none d-flex"
                    data-toggle="dropdown"
                    href="#"
                  >
                    {/*CREATE PROBLEMS WITH RECORD RULES*/}
                    {/*<span class="avatar avatar-md brround" t-att-style="'background-image: url(data:image/png;base64,' + str(user_id.image.decode()) + ')'"></span>*/}
                    <span
                      className="avatar avatar-md brround"
                      style={{
                        backgroundImage:
                          'url("/static/images/user-avatar.png")',
                      }}
                    />
                    <span className="ml-2 d-none d-lg-block">
                      <span className="text-dark">
                        {!this.props.cS.active_user
                          ? "Loading..."
                          : this.props.cS.active_user.user_id[0].name}
                      </span>
                    </span>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                    {/*<Link to="/billing" className="dropdown-item">*/}
                    {/*  <i className="dropdown-icon fas fa-file-invoice-dollar"></i>{" "}*/}
                    {/*  Billing*/}
                    {/*</Link>*/}
                    <Link to="/logout" className="dropdown-item">
                      <i className="dropdown-icon fe fe-power" /> Log Out
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

export default Header;
