import React from "react";
import global from "../config";
import "../config";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

import { Redirect } from "react-router-dom";
import BranchActionsModal from "./BranchActionsModal";
import swal from "sweetalert2/dist/sweetalert2.all.min.js";
import Swal from "sweetalert2";
const axios = require("axios").default;
axios.defaults.withCredentials = true;
const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip placement="top" arrow classes={classes} {...props} />;
}

class BranchActions extends React.Component {
  constructor(props) {
    super(props);
    this.deployToStage = this.deployToStage.bind(this);
    this.deployToProduction = this.deployToProduction.bind(this);
    this.reload = this.reload.bind(this);
    this.delete = this.delete.bind(this);
    // this.snapshot = this.snapshot.bind(this);
    this.headers = global.header_api;
    this.state = {
      loading: false,
      redirectCreate: false,
      redirectDelete: false,
    };
  }

  deployToStage() {
    this.setState({
      loading: true,
    });
    const body = {
      branch_id: this.props.branchId.id,
      environment: "staging",
    };
    axios
      .post(global.url_api + "/rest/data/build/create", body, {
        headers: this.headers,
      })
      .then((res) => {
        this.setState({
          redirectCreate: true,
        });
        const data = res.data;
        this.setState({
          loading: false,
        });
        if (this.props.cS.active_branch) {
          this.props.handleCS(data);
        } else {
          this.props.handleCS({ active_project: data.active_project });
        }
        window.swal(
          "Congratulations!",
          "Your stack has been successfully deployed",
          "success"
        );
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        window.swal({
          title: "Alert",
          text:
            "Ops! Something went wrong:\n\n" + error.response.data.description,
          type: "error",
        });
      });
  }

  deployToProduction() {
    this.setState({
      loading: true,
    });
    const body = {
      branch_id: this.props.branchId.id,
      environment: "production",
    };
    axios
      .post(global.url_api + "/rest/data/build/create", body, {
        headers: this.headers,
      })
      .then((res) => {
        this.setState({
          redirectCreate: true,
        });
        const data = res.data;
        this.setState({
          loading: false,
        });
        if (this.props.cS.active_branch) {
          this.props.handleCS(data);
        } else {
          this.props.handleCS({ active_project: data.active_project });
        }
        window.swal(
          "Congratulations!",
          "Your stack has been successfully deployed",
          "success"
        );
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        window.swal({
          title: "Alert",
          text:
            "Ops! Something went wrong:\n\n" + error.response.data.description,
          type: "error",
        });
      });
  }

  reload() {
    this.setState({
      loading: true,
    });
    axios
      .post(
        global.url_api +
          "/rest/data/build/" +
          String(this.props.branchId.id) +
          "/update",
        {},
        {
          headers: this.headers,
        }
      )
      .then((res) => {
        this.setState({
          loading: false,
        });
        window.swal(
          "Congratulations!",
          "Your instance has been successfully restarted",
          "success"
        );
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        window.swal({
          title: "Alert",
          text:
            "Ops! Something went wrong:\n\n" + error.response.data.description,
          type: "error",
        });
      });
  }

  cSnapshot = () => {
    this.props.handleCS({
      modal: {
        branchId: this.props.branchId,
        onClick: this.createSnapshot,
      },
    });
  };

  createSnapshot = (description) => {
    this.setState({
      loading: true,
    });
    const body = {
      branch_id: this.props.branchId.id,
      description: description,
    };
    axios
      .post(global.url_api + "/rest/data/snapshot/create", body, {
        headers: this.headers,
      })
      .then((res) => {
        this.setState({
          loading: false,
        });
        const data = res.data;
        if (this.props.cS.active_branch) {
          this.props.handleCS(data);
        } else {
          this.props.handleCS({ active_project: data.active_project });
        }
        window.swal(
          "Congratulations!",
          "Your snapshot has been successfully created",
          "success"
        );
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        swal.fire({
          title: "Alert",
          html:
            "Ops! Something went wrong:\n\n" + error.response.data.description,
          icon: "error",
          confirmButtonColor: "red",
          confirmButtonText: "Annulla",
        });
      });
  };

  delete() {
    var that = this;

    this.setState({
      loading: true,
    });
    window.swal(
      {
        title: "WARNING!",
        text: "Please be careful, deleting will erase all data! Are you sure you wish to proceed?",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn-danger",
        confirmButtonText: "Delete it!",
        cancelButtonText: "Close",
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
      },
      (inputValue) => {
        this.setState({
          loading: false,
        });
        if (inputValue) {
          axios
            .delete(
              global.url_api +
                "/rest/data/build/" +
                String(this.props.branchId.id),
              {
                headers: this.headers,
              }
            )
            .then((res) => {
              this.setState({
                redirectDelete: true,
                loading: false,
              });
              const data = res.data;
              if (this.props.cS.active_branch) {
                this.props.handleCS(data);
              } else {
                this.props.handleCS({ active_project: data.active_project });
              }
              window.swal(
                "Congratulations!",
                "Your instance has been successfully deleted",
                "success"
              );
            })
            .catch((error) => {
              this.setState({
                loading: false,
              });
              window.swal({
                title: "Alert",
                text:
                  "Ops! Something went wrong:\n\n" +
                  error.response.data.description,
                type: "error",
              });
            });
        }
      }
    );
  }
  deleteBranch() {
    var that = this;

    this.setState({
      loading: true,
    });
    Swal(
      {
        title: "WARNING!",
        text: "Please be careful, deleting this branch you'll never can go back, continue?",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn-danger",
        confirmButtonText: "Delete it!",
        cancelButtonText: "Close",
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
      },
      (inputValue) => {
        this.setState({
          loading: false,
        });
        if (inputValue) {
          axios
            .delete(
              global.url_api +
                "/rest/data/branch/" +
                String(this.props.branchId.id),
              {
                headers: this.headers,
              }
            )
            .then((res) => {
              this.setState({
                redirectDelete: true,
                loading: false,
              });
              const data = res.data;
              if (this.props.cS.active_branch) {
                this.props.handleCS(data);
              } else {
                this.props.handleCS({ active_project: data.active_project });
              }
              window.swal(
                "Congratulations!",
                "Your branch has been successfully deleted",
                "success"
              );
            })
            .catch((error) => {
              this.setState({
                loading: false,
              });
              window.swal({
                title: "Alert",
                text:
                  "Ops! Something went wrong:\n\n" +
                  error.response.data.description,
                type: "error",
              });
            });
        }
      }
    );
  }
  render() {
    return (
      <React.Fragment>
        {this.state.redirectDelete && (
          <Redirect
            to={
              "/project/" +
              String(this.props.cS.active_project.project_id[0].id)
            }
          />
        )}
        {this.state.redirectCreate && (
          <Redirect to={"/branch/" + String(this.props.branchId.id)} />
        )}

        <div className="btn-list">
          {this.props.branchId.environment === "not_assigned" ? (
            <React.Fragment>
              {this.props.cS.active_user &&
                this.props.cS.active_user.frontend_user_permissions
                  .branch_actions_deploy_stage && (
                  <BootstrapTooltip title="Deploy to Stage">
                    <button
                      type="button"
                      className={
                        this.state.loading
                          ? "btn btn-info btn-icon btn-sm btn-loading"
                          : "btn btn-info btn-icon btn-sm"
                      }
                      data-toggle="tooltip"
                      data-placement="top"
                      onClick={this.deployToStage}
                    >
                      <i className="fas fa-file-code"></i>
                    </button>
                  </BootstrapTooltip>
                )}
              {this.props.cS.active_user &&
                this.props.cS.active_user.frontend_user_permissions
                  .branch_actions_deploy_stage && (
                  <BootstrapTooltip title="Deploy to Production">
                    <button
                      type="button"
                      className={
                        this.state.loading
                          ? "btn btn-icon btn-primary btn-sm btn-loading"
                          : "btn btn-icon btn-primary btn-sm"
                      }
                      data-toggle="tooltip"
                      data-placement="top"
                      onClick={this.deployToProduction}
                    >
                      <i className="fas fa-rocket"></i>
                    </button>
                  </BootstrapTooltip>
                )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <BootstrapTooltip title="Reload">
                <button
                  type="button"
                  className={
                    this.state.loading
                      ? "btn btn-icon btn-primary btn-secondary btn-sm btn-loading"
                      : "btn btn-icon btn-primary btn-secondary btn-sm"
                  }
                  data-toggle="tooltip"
                  data-placement="top"
                  onClick={this.reload}
                >
                  <i className="fe fe-rotate-cw"></i>
                </button>
              </BootstrapTooltip>

              {this.props.cS.active_user &&
                this.props.cS.active_user.frontend_user_permissions
                  .branch_actions_create_snapshot && (
                  <BootstrapTooltip title="Create Snapshot">
                    <button
                      type="button"
                      className={
                        this.state.loading
                          ? "btn btn-icon btn-primary btn-secondary btn-sm btn-loading"
                          : "btn btn-icon btn-primary btn-secondary btn-sm"
                      }
                      data-toggle="tooltip"
                      data-placement="top"
                      data-toggle="modal"
                      data-target="#branchActionsCreateSnapshotModal"
                      onClick={this.cSnapshot}
                    >
                      <i className="fe fe-copy"></i>
                    </button>
                  </BootstrapTooltip>
                )}

              {this.props.cS.active_user &&
                this.props.cS.active_user.frontend_user_permissions
                  .build_actions_delete && (
                  <BootstrapTooltip title="Delete">
                    <button
                      type="button"
                      className={
                        this.state.loading
                          ? "btn btn-icon btn-pinterest btn-sm btn-loading"
                          : "btn btn-icon btn-pinterest btn-sm"
                      }
                      data-toggle="modal"
                      data-target="#delModal"
                      data-placement="top"
                      onClick={this.delete}
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                  </BootstrapTooltip>
                )}
            </React.Fragment>
          )}
          {this.props.cS.active_user &&
            this.props.cS.active_user.frontend_user_permissions
              .branch_actions_delete  && (
            <BootstrapTooltip title="Delete Branch">
              <button
                type="button"
                className={
                  this.state.loading
                    ? "btn btn-icon btn-danger btn-sm btn-loading"
                    : "btn btn-icon btn-danger btn-sm"
                }
                data-toggle="modal"
                data-placement="top"
                data-target="#branchActionsDeleteModal"
                onClick={() => {
                  this.props.handleCS({ selectedBranch: this.props.branchId });
                }}
              >
                  <i className=" fe fe-git-branch"></i>
              </button>
            </BootstrapTooltip>
          )}
          {/*{this.props.branchId.environment !== 'not_assigned' && (*/}
          {/*<button type="button"*/}
          {/*className="btn btn-icon btn-pinterest btn-sm"*/}
          {/*data-toggle="modal" data-target="#delModal"*/}
          {/*data-placement="top" title="Delete">*/}
          {/*<i className="fa fa-wrench"></i></button>*/}
          {/*)}*/}
        </div>
      </React.Fragment>
    );
  }
}

export default BranchActions;
