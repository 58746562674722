import React, { Component } from "react";
import "./GitcommandRow.css";
import { Trans } from "react-i18next";
export default class GitcommandRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commitmessage: " 'Commit message' ",
      command:
        "git clone --recurse-submodules --branch master git@github.com: " +
        this.props.cS.active_project.project_id[0].git_repo_id.name,
      finalmessage: "",
    };
  }
  componentDidMount() {}
  setCommitMessage(string) {
    string.replace('"', "");
    let res = '"' + string + '"';
    this.setState({ commitmessage: res });
  }
  render() {
    return (
      <div className="row">
        <div className="col-xl-12 col-md-12">
          <div className="card">
            <div className="card-header-loader"></div>
            <div className="card-header-loader"></div>
            <div className="crd-body p-6">
              <div className="panel panel-primary">
                <div className=" tab-menu-heading">
                  <div className="tabs-menu1 ">
                    <ul className="nav panel-tabs">
                      <li className="">
                        <a
                          onClick={() =>
                            this.setState({
                              command:
                                "git clone --recurse-submodules --branch master git@github.com: " +
                                this.props.cS.active_project.project_id[0]
                                  .git_repo_id.name,
                            })
                          }
                          id="tab1-button"
                          href="#clone"
                          className="active"
                          data-toggle="tab"
                        >
                          <span
                            className="iconify"
                            data-icon="clarity:clone-line"
                            data-inline="false"
                          ></span>
                          Clone
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            this.setState({
                              command:
                                "git merge staging-1 && git push -u origin master ",
                            })
                          }
                          id="tab2-button"
                          href="#merge"
                          data-toggle="tab"
                        >
                          <span
                            className="iconify"
                            data-icon="mdi-call-merge"
                            data-inline="false"
                          ></span>
                          Merge
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() =>
                            this.setState({
                              command:
                                "git submodule add -b master <URL> <PATH> && git commit -a && git push -u origin master ",
                            })
                          }
                          href="#submodule"
                          data-toggle="tab"
                        >
                          <i className="fas fa-boxes"></i>
                          Submodule
                        </a>
                      </li>

                      <li>
                        <a
                          onClick={() =>
                            this.setState({
                              command:
                                "git checkout -b feature-1 master && git push -u origin feature-1 ",
                            })
                          }
                          href="#fork"
                          data-toggle="tab"
                        >
                          <i className="fas fa-code-branch"></i>Fork
                        </a>
                      </li>
                      {/*<li><a href="#tab7" data-toggle="tab">Shell</a></li>*/}
                      {/*<li><a href="#tab7" data-toggle="tab">Editor</a></li>*/}
                    </ul>
                  </div>
                </div>
                <div className="panel-body tabs-menu-bodyGIT">
                  <div className="tab-content">
                    <div className="tab-pane active " id="clone">
                      <div className="form-group">
                        <div className="form-group">
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              readOnly="readOnly"
                              value={this.state.command}
                            />
                            <span className="input-group-append">
                              <button className="btn btn-primary" type="button">
                                <i className=" fa fa-play"></i>
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>
                      <span className="info_box">
                        <i className="fas fas fa-info-circle"></i>{" "}
                        <Trans i18nKey="clone_info">
                          This command is used to obtain a repository from an
                          existing URL.
                        </Trans>
                      </span>
                    </div>
                    <div className="tab-pane " id="merge">
                      <div className="form-group">
                        <div className="form-group">
                          <div className="input-group">
                            <input
                              type="text"
                              readOnly="readOnly"
                              className="form-control"
                              value={this.state.command}
                            />
                            <span className="input-group-append">
                              <button className="btn btn-primary" type="button">
                                <i className=" fa fa-play"></i>
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>
                      <span className="info_box">
                        <i className="fas fas fa-info-circle"></i>
                        <Trans i18nKey="merge_info">
                          This command merges the specified branch’s history
                          into the current branch.
                        </Trans>
                      </span>
                    </div>
                    <div className="tab-pane " id="submodule">
                      <div className="form-group">
                        <div className="form-group">
                          <div className="input-group">
                            <input
                              type="text"
                              readOnly="readOnly"
                              className="form-control"
                              value={this.state.command}
                            />
                            <span className="input-group-append">
                              <button className="btn btn-primary" type="button">
                                <i className=" fa fa-play"></i>
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>
                      <span className="info_box">
                        <i className="fas fas fa-info-circle"></i>
                        <Trans i18nKey="submodule_info">
                          A git submodule is a record within a host git
                          repository that points to a specific commit in another
                          external repository.
                        </Trans>
                      </span>
                    </div>
                    <div className="tab-pane " id="fork">
                      <div className="form-group">
                        <div className="form-group">
                          <div className="input-group">
                            <input
                              type="text"
                              readOnly="readOnly"
                              className="form-control"
                              value={this.state.command}
                            />
                            <span className="input-group-append">
                              <button className="btn btn-primary" type="button">
                                <i className=" fa fa-play"></i>
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>
                      <span className="info_box">
                        <i className="fas fas fa-info-circle"></i>
                        <Trans i18nKey="fork_info">
                          Forking it is basically making a copy of the
                          repository, but with a link back to the original.
                        </Trans>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
