import React from "react";
// import global from "../config";
import SnapshotTableRow from "./SnapshotTableRow";
import { Trans } from "react-i18next";
/* eslint-disable */
class SnapshotTable extends React.Component {
  render() {
    return (
      <React.Fragment>
        {this.props.branchId.snapshot_ids.length > 0 && (
          <div className="table-responsive">
            <table id="example-2" className="table table-striped table-bordered">
              <thead>
                <tr>
                <th className="wd-15p border-bottom-0">
                    <Trans i18nKey="created_at">Created at</Trans>
                  </th>
                  <th className="wd-15p border-bottom-0">
                    <Trans i18nKey="name">Name</Trans>
                  </th>
                  <th className="wd-15p border-bottom-0">
                    <Trans i18nKey="description">Description</Trans>
                  </th>
                  
                  <th className="wd-15p border-bottom-0">
                    <Trans i18nKey="ready_to_use">Ready</Trans>
                  </th>
                  <th className="wd-15p border-bottom-0">
                    <Trans i18nKey="actions">Actions</Trans>
                  </th>
                </tr>
              </thead>
              <tbody>
              {this.props.branchId.snapshot_ids.map((snapshot, index) => {
                  return (
                    <SnapshotTableRow
                      key={index}
                      index={index}
                      branchId={this.props.branchId}
                      snapshotId={snapshot}
                      cS={this.props.cS}
                      handleCS={this.props.handleCS}
                    />
                  );
              })}
              </tbody>
            </table>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default SnapshotTable;
