import React from "react";
import BranchActionsModal from "./BranchActionsModal";
import SnapshotActionsModal from "./SnapshotActionsModal";
// import global from "../config";
// import { Link, Redirect } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import Menu from "./Menu";

class Container extends React.Component {
  render() {
    const ContainerName = this.props.activeContainer;

    return (
      <React.Fragment>
        <Header {...this.props} />
        <Menu {...this.props} />

          <div className=" app-content my-3 my-md-5">
              <div style={{ marginBottom: "35px" }} className="side-app">
            <ContainerName {...this.props} />
          </div>

          <Footer />
          <BranchActionsModal
              cS={this.props.cS}
              handleCS={this.props.handleCS}
          />
          <SnapshotActionsModal
              cS={this.props.cS}
              handleCS={this.props.handleCS}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default Container;
