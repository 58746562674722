import React , {useState , useEffect} from 'react'
import {Trans} from 'react-i18next';

const SnapshotActionsModal = ({cS}) => {
      const [ name, setName ] = useState("")
      useEffect ( () => { if(cS.modal) { setName (cS.modal.branchId.name + "-new") } } , [cS])

      return (<div className="modal fade" id="branchActionsRestoreSnapshotModal" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="restore-snapshot-modal"><Trans i18nKey="create_new_branch">Restore into a new branch</Trans></h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label htmlFor="recipient-name" className="form-control-label"><Trans i18nKey="name">Name</Trans></label>
                  <input type="text" className="form-control" id="new-branch-name" onChange={(e)=>setName(e.target.value)} value={name}/>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={()=> cS.modal ? cS.modal.onClick(name) : ""}>Deploy</button>
            </div>
          </div>
        </div>
      </div>)
}

export default SnapshotActionsModal
