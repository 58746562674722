import React from "react";
// import global from "../config";
import { Trans } from "react-i18next";

class BranchDetails extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div id="accordion" className="w-100 ">
          <div className="card mb-0 border">
            <div className="accor bg-primary" id="headingOne">
              <h5 className="m-0">
                <a
                  href="#collapseOne"
                  className="text-white"
                  data-toggle="collapse"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  URL
                </a>
              </h5>
            </div>
            <div
              id="collapseOne"
              className="collapse show"
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <div className="card-body">
                <ul className="list-group">
                  <li className="list-group-item">
                    <a
                      href={
                        "https://" + this.props.branchId.last_build_id.build_url
                      }
                      target="_blank"
                    >
                      {"https://" + this.props.branchId.last_build_id.build_url}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card mb-0 border">
            <div className="accor  bg-primary" id="headingTwo">
              <h5 className="m-0">
                <a
                  href="#collapseTwo"
                  className="collapsed text-white"
                  data-toggle="collapse"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  <Trans i18nKey="plan_details">PLAN DETAILS</Trans>
                </a>
              </h5>
            </div>
            <div
              id="collapseTwo"
              className="collapse b-b0"
              aria-labelledby="headingTwo"
              data-parent="#accordion"
            >
              <div className="card-body">
                <ul className="list-group">
                  <li className="list-group-item">
                    {" "}
                    <Trans i18nKey="odoo_version">Odoo Version</Trans>:{" "}
                    {this.props.projectId.odoo_version}
                  </li>
                  <li className="list-group-item">
                    {" "}
                    {this.props.projectId.plan_id.name}
                  </li>
                  <li className="list-group-item">
                    {" "}
                    <Trans i18nKey="max_prod_env">
                      Max. Production Environment
                    </Trans>
                    : {this.props.projectId.max_prod_env}
                  </li>
                  <li className="list-group-item">
                    {" "}
                    <Trans i18nKey="max_stage_env">
                      Max. Staging Environment
                    </Trans>
                    :{this.props.projectId.max_stage_env}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card border mb-0">
            <div className="accor  bg-primary" id="headingThree">
              <h5 className="m-0">
                <a
                  href="#collapseThree"
                  className="collapsed text-white"
                  data-toggle="collapse"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  <Trans i18nKey="linked_repository">LINKED REPOSITORY</Trans>
                </a>
              </h5>
            </div>
            <div
              id="collapseThree"
              className="collapse b-b0"
              aria-labelledby="headingThree"
              data-parent="#accordion"
            >
              <div className="card-body">
                <ul className="list-group">
                  <li className="list-group-item">
                    {" "}
                    <Trans i18nKey="type">Type</Trans>:{" "}
                    {this.props.projectId.git_repo_id.type}
                  </li>
                  <li className="list-group-item">
                    {" "}
                    <Trans i18nKey="repo_name">Repo Name</Trans>:{" "}
                    {this.props.projectId.git_repo_id.name}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card border mb-0">
            <div className="accor  bg-primary" id="headingFour">
              <h5 className="m-0">
                <a
                  href="#collapseFour"
                  className="collapsed text-white"
                  data-toggle="collapse"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  <Trans i18nKey="build_information">BUILD INFORMATIONS</Trans>
                </a>
              </h5>
            </div>
            <div
              id="collapseFour"
              className="collapse b-b0"
              aria-labelledby="headingFour"
              data-parent="#accordion"
            >
              <div className="card-body">
                <ul className="list-group">
                  <li className="list-group-item">
                    {" "}
                    <Trans i18nKey="identifier">Identifier</Trans>:{" "}
                    {this.props.branchId.last_build_id.name}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/*<p><a*/}
        {/*t-att-href="'https://' + branch_id.last_build_id.build_url"*/}
        {/*target="_blank">URL - <t*/}
        {/*t-esc="'https://' + branch_id.last_build_id.build_url"/></a>*/}
        {/*</p>*/}
      </React.Fragment>
    );
  }
}

export default BranchDetails;
