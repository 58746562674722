import axios from "axios";
import React from "react";
import "status-indicator/styles.css";
import global from "../config";
import SnapshotActions from "./SnapshotActions";
import Swal from "sweetalert2/dist/sweetalert2.all.min.js";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

axios.defaults.withCredentials = true;
const Toast = Swal.mixin({
  toast: true,
  position: "bottom-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});
var refreshIntervalId;
const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip placement="top" arrow classes={classes} {...props} />;
}
class SnapshotTableRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = { is_ready_to_use: this.props.snapshotId.is_ready_to_use };
  }
  settaReady() {
    axios
      .get(global.url_api + "/rest/data/snapshot/" + this.props.snapshotId.id)
      .then((res) => {
        if (res && res.data.is_ready_to_use) {
          clearInterval(refreshIntervalId);
          this.setState({
            is_ready_to_use: true,
          });
          Toast.fire({
            icon: "success",
            title: "Snapshot Ready",
            text: "Lo Snapshot è pronto!",
          });
        }
      })
      .catch(function (error) {
        console.log("errore", error);
      });
  }

  componentDidMount() {
    if (!this.state.is_ready_to_use) {
      refreshIntervalId = setInterval(() => {
        this.settaReady();
      }, 5000);
    }
  }
  componentWillUnmount() {
    clearInterval(refreshIntervalId);
  }

  formatDatewithHours(data, lang = "it-IT") {
    if (data)
      return new Date(data).toLocaleString(lang, {
        month: "long",
        day: "2-digit",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
    else return "";
  }
  render() {
    return (
      <React.Fragment>
        <tr>
          <td>{this.formatDatewithHours(this.props.snapshotId.create_date)}</td>
          <td>
            <BootstrapTooltip title="Clicca per copiare il nome">
              <p
                style={{
                  height: "24px",
                  marginBottom: 4,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
                className="d-block font-16 lh-24 normal text-gray "
                onClick={(e) => {
                  var elem = document.createElement("textarea");
                  document.body.appendChild(elem);
                  elem.value = this.props.snapshotId.name;
                  elem.select();
                  document.execCommand("copy");
                  Toast.fire({
                    icon: "info",
                    title: "Copiato negli appunti",
                    text: this.props.snapshotId.name,
                  });
                  document.body.removeChild(elem);
                }}
              >
                {this.props.snapshotId.name}
              </p>
            </BootstrapTooltip>
          </td>
          <td>
            <BootstrapTooltip title={this.props.snapshotId.description}>
              <p
                style={{
                  height: "24px",
                  marginBottom: 4,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth:'20vw',
                  textOverflow: "ellipsis",
                }}
                className="d-block font-16 lh-24 normal text-gray "
              >
                {this.props.snapshotId.description}
              </p>
            </BootstrapTooltip>
          </td>
          <td>
            {this.state.is_ready_to_use ? (
              <status-indicator positive pulse />
            ) : (
              <status-indicator intermediary pulse />
            )}
          </td>
          <td>
            {this.state.is_ready_to_use && (
              <SnapshotActions
                branchId={this.props.branchId}
                snapshotId={this.props.snapshotId}
                cS={this.props.cS}
                handleCS={this.props.handleCS}
              />
            )}
          </td>
        </tr>
      </React.Fragment>
    );
  }
}

export default SnapshotTableRow;
