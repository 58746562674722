import React from "react";
// import global from "../config";

class LoadingSpinner extends React.Component {
  render() {
    if (this.props.err)
      return (
        <p>
          OPS! Abbiamo riscontrato un errore durante il caricamento, per favore{" "}
          <button
            onClick={() => window.location.reload()}
            class="btn btn-primary"
          >
            Ricarica
          </button>
        </p>
      );
    else
      return (
        <div className="text-center vertically">
          <div className="lds-facebook">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      );
  }
}

export default LoadingSpinner;
