import React from "react";
import global from "../config";

class ButtonConnectGit extends React.Component {
  handleClick(url) {
    window.location.href = url;
  }

  render() {
    return (
      <React.Fragment>
        <button
          type="button"
          className="btn btn-icon btn-github btn-sm"
          data-toggle="tooltip"
          data-placement="top"
          title="Connect with Github"
          onClick={() =>
            this.handleClick(global.url_api + "/github/connect?project=" + this.props.projectId)
          }
        >
          <i className="fab fa-github text-white"></i>
        </button>
        {/*&nbsp;*/}
        {/*<button*/}
        {/*  type="button"*/}
        {/*  className="btn btn-icon btn-flickr btn-sm"*/}
        {/*  data-toggle="tooltip"*/}
        {/*  data-placement="top"*/}
        {/*  title="Connect with Github"*/}
        {/*  onClick={() =>*/}
        {/*    this.handleClick(*/}
        {/*      global.url_api + "/bitbucket/connect?project=" + this.props.projectId*/}
        {/*    )*/}
        {/*  }*/}
        {/*>*/}
        {/*  <i className="fab fa-bitbucket text-white"></i>*/}
        {/*</button>*/}
      </React.Fragment>
    );
  }
}

export default ButtonConnectGit;
