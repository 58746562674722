import React from "react";
import global from "../config";
import { LazyLog, ScrollFollow } from "react-lazylog";
import LoadingSpinner from "./LoadingSpinner";
import { Button } from "@material-ui/core";
import "./BranchLogViewer.css";
const axios = require("axios").default;
axios.defaults.withCredentials = true;

class BranchLogViewer extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      data: null,
      wsRetry: false,
      follow: true,
      wsRetryCounter: 0,
      err: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const headers = global.header_api;
    axios
      .get(global.url_api + "/rest/data/tbox/" + String(this.props.branchId), {
        headers: headers,
      })
      .then((res) => {
        const data = res.data;
        this.setState({ data: data, err: false });
      })
      .catch(function (error) {
        console.log(error.toJSON());
        this.setState({ err: true });
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const url = "wss://" + global.ws_url + "/logger";
    // eslint-disable-next-line
    var socket = null;
    const that = this;
    return (
      <React.Fragment>
        <div className="button_UI_container">
          {this.state.data && this.props.health_status ? (
            <Button
              className="custombtnUI"
              variant="contained"
              onClick={() => this.setState({ follow: !this.state.follow })}
            >
              Autofollow: {this.state.follow === false ? "off" : "on"}
            </Button>
          ) : null}
        </div>
        {this.state.data && this.props.health_status ? (
          <ScrollFollow
            startFollowing
            render={({ onScroll, follow, startFollowing, stopFollowing }) => (
              <LazyLog
                id="log"
                url={url}
                stream
                follow={this.state.follow}
                onScroll={onScroll}
                /* extraLines={100} */
                enableSearch
                caseInsensitive
                selectableLines
                websocket
                websocketOptions={{
                  onOpen: (e, sock) => {
                    document
                      .getElementById("log")
                      .addEventListener("wheel", function () {
                        that.setState({ follow: false });
                      });
                    console.log(
                      "[b" + this.state.data.tbox_auth.build + "-L] CONNECTED!"
                    );
                    socket = sock;
                    sock.send(
                      JSON.stringify({
                        build: this.state.data.tbox_auth.build,
                        ns: this.state.data.tbox_auth.ns,
                        server: this.state.data.tbox_auth.server,
                        ca: this.state.data.tbox_auth.ca,
                        token: this.state.data.tbox_auth.token,
                      })
                    );
                  },
                  onError: (e, sock) => {
                    // console.log(e);
                  },
                  onClose: (e, sock) => {},
                }}
              />
            )}
          />
        ) : (
          <LoadingSpinner err={this.state.err} />
        )}
      </React.Fragment>
    );
  }
}

export default BranchLogViewer;
