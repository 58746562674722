import React from "react";
// import global from "../config";

import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import "./Menu.css";
class Menu extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="app-sidebar__overlay" data-toggle="sidebar"></div>
        <aside className="app-sidebar">
          <div className="position-relative">
            <button
              className="close_drawer_button"
              onClick={() => {
                let sidebar = $(".app-sidebar");
                console.log("Actual left", sidebar.css("left"));
                if (sidebar.css("left") === "0px") {
                  sidebar.css("left", -sidebar.outerWidth() + "px");
                } else {
                  sidebar.css("left", "0px");
                }
                $("#cross").toggleClass("d-none");
            $("#menu_backdrop").toggleClass("d-none");
            $("#bars").toggleClass("d-none");
              }}
            >
              <i id="cross" className="fa fa-times d-none" />
              <i id="bars" className="fas fa-bars" />
            </button>
          </div>
          <div className="app-sidebar__user">
            <div className="user-body">
              <span
                className="avatar avatar-lg brround text-center"
                style={{
                  backgroundImage: 'url("/static/images/user-avatar.png")',
                }}
              />
            </div>
            <div className="user-info">
              <a href="#" className="ml-2">
                <span className="text-dark app-sidebar__user-name font-weight-semibold">
                  {!this.props.cS.active_user
                    ? "Loading..."
                    : this.props.cS.active_user.user_id[0].name}
                </span>
                {/*<br/>*/}
                {/*<span className="text-muted app-sidebar__user-name text-sm"> Web Designer</span>*/}
              </a>
            </div>
          </div>
          <ul className="side-menu">
            <li>
              <Link
                className={
                  this.props.cS.active_menu === "dashboard"
                    ? "side-menu__item active"
                    : "side-menu__item"
                }
                to="/"
              >
                <i className="side-menu__icon fe fe-grid" />{" "}
                <span className="side-menu__label">Dashboard</span>
              </Link>
            </li>
            <li>
              <Link
                className={
                  this.props.cS.active_menu === "projects"
                    ? "side-menu__item active"
                    : "side-menu__item"
                }
                to="/project/list"
              >
                <i className="side-menu__icon fe fe-box" />
                <span className="side-menu__label">
                  <Trans i18nKey="projects">Projects</Trans>
                </span>
              </Link>
            </li>
            {[
              "project",
              "project_builds",
              "project_settings",
              "branch",
              "branch_logs",
              "branch_settings",
            ].includes(this.props.cS.active_menu) && (
              <li
                className={
                  this.props.cS.menu_expand_project
                    ? "slide is-expanded"
                    : "slide"
                }
              >
                <Link
                  className={
                    ["project", "project_builds", "project_settings"].includes(
                      this.props.cS.active_menu
                    )
                      ? "side-menu__item active"
                      : "side-menu__item"
                  }
                  to="#"
                  data-toggle="slide"
                  onClick={() =>
                    this.props.handleCS({
                      menu_expand_project: !this.props.cS.menu_expand_project,
                    })
                  }
                >
                  <i className="side-menu__icon fe fe-box" />
                  {this.props.cS.active_project ? (
                    <span className="side-menu__label">
                      {this.props.cS.active_project.project_id[0].name}
                    </span>
                  ) : (
                    <span className="side-menu__label">Loading...</span>
                  )}
                  <i className="angle fas fa-angle-right" />
                </Link>
                <ul className="slide-menu">
                  <li>
                    <Link
                      className={
                        this.props.cS.active_menu === "project"
                          ? "slide-item active"
                          : "slide-item"
                      }
                      to={
                        this.props.cS.active_project
                          ? "/project/" +
                            String(
                              this.props.cS.active_project.project_id[0].id
                            )
                          : ""
                      }
                    >
                      <i className="side-menu__icon fe fe-arrow-right-circle" />
                      <Trans i18nKey="overview">Overview</Trans>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={
                        this.props.cS.active_menu === "project_settings"
                          ? "slide-item active"
                          : "slide-item"
                      }
                      to={
                        this.props.cS.active_project
                          ? "/project/" +
                            String(
                              this.props.cS.active_project.project_id[0].id
                            ) +
                            "/settings"
                          : ""
                      }
                    >
                      <i className="side-menu__icon fe fe-settings" />
                      <Trans i18nKey="settings">Settings</Trans>
                    </Link>
                  </li>
                  {/*<li>*/}
                  {/*  <Link*/}
                  {/*    className={*/}
                  {/*      this.props.cS.active_menu === "project_builds"*/}
                  {/*        ? "slide-item active"*/}
                  {/*        : "slide-item"*/}
                  {/*    }*/}
                  {/*    to={*/}
                  {/*      this.props.cS.active_project*/}
                  {/*        ? "/project/" +*/}
                  {/*          String(*/}
                  {/*            this.props.cS.active_project.project_id[0].id*/}
                  {/*          ) +*/}
                  {/*          "/builds"*/}
                  {/*        : ""*/}
                  {/*    }*/}
                  {/*  >*/}
                  {/*    <i className="side-menu__icon fe fe-server" />*/}
                  {/*    <span*/}
                  {/*    className="side-menu__label">*/}
                  {/*      Builds*/}
                  {/*    </span>*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                </ul>
              </li>
            )}
            {["branch", "branch_logs", "branch_settings"].includes(
              this.props.cS.active_menu
            ) && (
              <li
                className={
                  this.props.cS.menu_expand_branch
                    ? "slide is-expanded"
                    : "slide"
                }
              >
                <Link
                  className={
                    this.props.cS.active_menu === "branch"
                      ? "side-menu__item active"
                      : "side-menu__item"
                  }
                  to="#"
                  data-toggle="slide"
                  onClick={() =>
                    this.props.handleCS({
                      menu_expand_branch: !this.props.cS.menu_expand_branch,
                    })
                  }
                >
                  <i className="side-menu__icon fe fe-git-branch" />{" "}
                  {this.props.cS.active_branch ? (
                    <span className="side-menu__label">
                      {this.props.cS.active_branch.branch_id[0].name}
                    </span>
                  ) : (
                    <span className="side-menu__label">Loading...</span>
                  )}
                  <i className="angle fas fa-angle-right" />
                </Link>
                <ul className="slide-menu">
                  <li>
                    <Link
                      className={
                        this.props.cS.active_menu === "branch"
                          ? "slide-item active"
                          : "slide-item"
                      }
                      to={
                        this.props.cS.active_branch
                          ? "/branch/" +
                            String(this.props.cS.active_branch.branch_id[0].id)
                          : ""
                      }
                    >
                      <i className="side-menu__icon fe fe-arrow-right-circle" />
                      <Trans i18nKey="overview">Overview</Trans>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={
                        this.props.cS.active_menu === "branch_logs"
                          ? "slide-item active"
                          : "slide-item"
                      }
                      to={
                        this.props.cS.active_branch
                          ? "/branch/" +
                            String(
                              this.props.cS.active_branch.branch_id[0].id
                            ) +
                            "/logs"
                          : ""
                      }
                    >
                      <i className="side-menu__icon fe fe-file-text" />
                      <span className="side-menu__label">
                        <Trans i18nKey="logs">Logs</Trans>
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={
                        this.props.cS.active_menu === "branch_settings"
                          ? "slide-item active"
                          : "slide-item"
                      }
                      to={
                        this.props.cS.active_branch
                          ? "/branch/" +
                            String(
                              this.props.cS.active_branch.branch_id[0].id
                            ) +
                            "/settings"
                          : ""
                      }
                    >
                      <i className="side-menu__icon fe fe-settings" />
                      <span className="side-menu__label">
                        <Trans i18nKey="settings">Settings</Trans>
                      </span>
                    </Link>
                  </li>
                </ul>
              </li>
            )}
          </ul>
        </aside>
        <div
          className="d-none"
          onClick={() => {
            let sidebar = $(".app-sidebar");
            console.log("Actual left", sidebar.css("left"));
            if (sidebar.css("left") === "0px") {
              sidebar.css("left", -sidebar.outerWidth() + "px");
            } else {
              sidebar.css("left", "0px");
            }
            $("#cross").toggleClass("d-none");
            $("#menu_backdrop").toggleClass("d-none");
            $("#bars").toggleClass("d-none");
          }}
          id="menu_backdrop"
        />
      </React.Fragment>
    );
  }
}

export default Menu;
