import React from "react";
// import global from "../config";
/* import ProjectList from "./ProjectList"; */
import { Trans } from "react-i18next";
import Zoom from "@material-ui/core/Zoom";
import { Tooltip } from "@material-ui/core";
class UserPublicKey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "Click to Copy",
    };
  }
  copytoclip() {
    var copyText = document.getElementById("inputkey");

    copyText.select();
    copyText.setSelectionRange(0, 99999); /*mobile devices*/

    document.execCommand("copy");
    copyText.setSelectionRange(0, 0); /*Per togliere l'highlights*/
    this.setState({ text: "Copied to Clipboard" });
  }
  render() {
    return (
      <React.Fragment>
        <div className="form-group">
          <label className="form-label">
            <Trans i18nKey="your_public_key">Your Public Key</Trans>
          </label>
          {/*Copy the Public key and add it in the user settings of your Git hosting service. You can add it as deploy key too. (For GitHub Users it is added automatically to your account by OdooStack)*/}
          <div className="input-icon">
            <span className="input-icon-addon">
              {/*<i class="fas fa-copy"></i>*/}
              <i className="fas fa-key"></i>
            </span>
            <Tooltip
              id="copytooltip"
              title={this.state.text}
              arrow
              TransitionComponent={Zoom}
              onClose={() => this.setState({ text: "Click to Copy" })}
            >
              <input
                type="text"
                className="form-control"
                style={{ cursor: "pointer" }}
                placeholder="OdooStack User's Public Key"
                value={this.props.userPublicKey}
                readOnly="readonly"
                id="inputkey"
                onClick={() => this.copytoclip()}
              />
            </Tooltip>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default UserPublicKey;
