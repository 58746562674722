import React from "react";
import global from "../config";
import "status-indicator/styles.css";
/* eslint-disable */
const axios = require("axios").default;
axios.defaults.withCredentials = true;

class BranchStatus extends React.Component {
  constructor(props) {
    super(props);
    this.connect = this.connect.bind(this);
    this._isMounted = false;
    this.state = {
      data: null,
      wss: new WebSocket("wss://" + global.ws_url + "/health"),
      is_available: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.connect();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.state.wss.close();
  }

  connect() {
    if (this._isMounted) {
      this.setState({
        is_available: false,
        wss: new WebSocket("wss://" + global.ws_url + "/health"),
      });

      const headers = global.header_api;
      axios
        .get(
          global.url_api + "/rest/data/tbox/" + String(this.props.branchId),
          {
            headers: headers,
          }
        )
        .then((res) => {
          const data = res.data;
          this.setState({ data: data });

          this.state.wss.onopen = () => {
            console.log("[b" + data.tbox_auth.build + "-H] CONNECTED!");
            this.state.wss.send(
              JSON.stringify({
                build: data.tbox_auth.build,
                ns: data.tbox_auth.ns,
                server: data.tbox_auth.server,
                ca: data.tbox_auth.ca,
                token: data.tbox_auth.token,
              })
            );
          };

          var that = this;
          this.state.wss.onmessage = (evt) => {
            // listen to data sent from the websocket server
            const message = JSON.parse(evt.data);
            message.status.conditions.forEach((entry) => {
              if (entry.type == "Available") {
                if (that._isMounted) {
                  that.setState({
                    is_available: entry.status === "True",
                  });
                  this.props.swap_status(entry.status === "True");
                }
              }
            });
            // console.log(message)
            // setTimeout(function () {
            //     that.state.wss.close()
            // }, 1000)
          };

          this.state.wss.onerror = (e) => {
            // console.log(e);
          };
          /* eslint-disable */
          this.state.wss.onclose = () => {
            console.log("[b" + data.tbox_auth.build + "-H] DISCONNECTED!");
            // automatically try to reconnect on connection loss
            setTimeout(
              function () {
                this.connect();
              }.bind(this),
              2000
            );
          };
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  render() {
    const url = "wss://" + global.ws_url + "/health";
    let socket = null;

    return (
      <React.Fragment>
        {this.state.is_available ? (
          <status-indicator positive pulse></status-indicator>
        ) : (
          <div className="progress progress-md">
            <div className="progress-bar progress-bar-indeterminate bg-primary"></div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default BranchStatus;
