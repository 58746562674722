import React from "react";
import global from "../config";
import LoadingSpinner from "./LoadingSpinner";
import ButtonConnectGit from "./ButtonConnectGit";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { Animated } from "react-animated-css";

const axios = require("axios").default;
axios.defaults.withCredentials = true;

class ProjectList extends React.Component {
  constructor(props) {
    super(props);
    this.props.handleCS({
      active_menu: "projects",
      active_project: null,
      active_branch: null,
    });
    this.state = {
      updated: false,
    };
  }

  componentDidMount() {
    const headers = global.header_api;
    axios
      .get(global.url_api + `/rest/data/user/`, {
        headers: headers,
      })
      .then((res) => {
        const data = res.data;
        this.props.handleCS({
          active_user: data,
        });
        this.setState({ updated: true });
      })
      .catch(function (error) {
        console.log(error.toJSON());
        this.setState({ err: true });
      });
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-header">
          <h3 className="page-title">
            {this.props.cS.active_user && this.state.updated ? (
              <Trans i18nKey="projects">Projects</Trans>
            ) : (
              "Loading..."
            )}
          </h3>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <Trans i18nKey="projects">Projects</Trans>
            </li>
          </ol>
        </div>

        {this.props.cS.active_user && this.state.updated ? (
          <div className="row">
            {this.props.cS.active_user.project_ids.map((project, index) => {
              return (
                <Animated
                  animationInDuration={200}
                  animationOutDuration={200}
                  animationOut="fadeOut"
                  isVisible={this.props.cS.active_user && this.state.updated}
                  animationInDelay={200}
                  key={index}
                  animationIn="zoomIn"
                  className="col-xl-3 col-md-12 col-lg-6"
                >
                  <Link to={project.is_init ? "/project/" + project.id : "#"}>
                    <div className="card">
                      <div
                        className={
                          project.is_init
                            ? "card-body-project"
                            : "card-body-project text-center"
                        }
                      >
                        <div className={project.is_init ? "float-left" : ""}>
                          <p>{project.name}</p>
                          <h4 className="mb-0">
                            {!project.is_init && (
                              <ButtonConnectGit projectId={project.id} />
                            )}
                          </h4>
                          {project.is_init && (
                            <p className=" mb-0">
                              <i
                                className="fe fe-git-branch text-primary"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Branches"
                              ></i>{" "}
                              {project.branch_count}
                            </p>
                          )}
                          {project.is_init && (
                            <p className=" mb-0">
                              <i
                                className="fe fe-server text-primary"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Active Instances"
                              ></i>{" "}
                              {project.build_count}
                            </p>
                          )}
                        </div>
                        <div className="float-right">
                          {project.is_init &&
                            project.git_repo_id.type === "github" && (
                              <span
                                t-if="project.is_init and project.git_repo_id.type === 'github'"
                                className="mini-stat-icon text-primary"
                              >
                                <i className="fab fa-github"></i>
                              </span>
                            )}
                          {/*{project.is_init &&*/}
                          {/*  project.git_repo_id.type === "bitbucket" && (*/}
                          {/*    <span*/}
                          {/*      t-if="project.is_init and project.git_repo_id.type === 'bitbucket'"*/}
                          {/*      className="mini-stat-icon text-primary"*/}
                          {/*    >*/}
                          {/*      <i className="fab fa-bitbucket"></i>*/}
                          {/*    </span>*/}
                          {/*  )}*/}
                        </div>
                      </div>
                    </div>
                  </Link>
                </Animated>
              );
            })}
          </div>
        ) : (
          <LoadingSpinner err={this.state.err} />
        )}
      </React.Fragment>
    );
  }
}

export default ProjectList;
